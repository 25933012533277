import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { storage } from '../../../index';

import { createFuzziAsync,
  getFuzzisAsync
} from '../../Dashboard/DashboardSlice';

import { maxChars, writersBlockArray } from '../../shared/sharedObjects';

import { Button,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
   } from '@material-ui/core';

import { CircularProgressWithLabel } from '../../shared/components/CircularProgressWithLabel';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import CasinoOutlinedIcon from '@material-ui/icons/CasinoOutlined';
import GifIcon from '@material-ui/icons/Gif';

import './AddToJar.css';

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../../Dashboard/FuzziJarPage/FuzziJarManager/FuzziJarManager.css';
import ReactGiphySearchbox from 'react-giphy-searchbox'




export function AddToJar(props) {
  const dispatch = useDispatch();

  const extractUrls = require('extract-urls');

  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");


  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [emojiBool, setEmojiBool] = useState(false);
  const [giphyBool, setGiphyBool] = useState(false);
  const [writersBlockBool, setWritersBlockBool] = useState(true);

  const [message, setMessage] = useState('');
  const [writersBlock, setWritersBlock] = useState('');
  const [characterCount, setCharacterCount] = useState(0);
  const [progress, setProgress] = useState(0);

  const handleImage = e => {
    if (url !== '') {
      deleteImage();
    }
    if (e.target.files[0]) {
      const img = e.target.files[0]
      // setImage(e.target.files[0]);

      handleUpload(img);
    }
  };

  const handleUpload = (image) => {
    setImage(image);
    const uid = props.user.uid ?? 'warmFuzzis';
    const uploadTask = storage.ref(`images/${uid}/${props.jarId}/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // const progress = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
      },
      error => {
        console.log(error);
      },
      () => {
        storage
          .ref(`images/${uid}/${props.jarId}`)
          .child(`${image.name}`)
          .getDownloadURL()
          .then(url => {
            setUrl(url);
          });
      }
    );
  };

  const deleteImage = () => {
    // Create a reference to the file to delete
    const uid = props.user.uid ?? 'warmFuzzis';
    var imageRef = storage.ref(`images/${uid}/${props.jarId}`).child(`${image.name}`);

    // Delete the file
    imageRef.delete().then(function() {
      // File deleted successfully
      console.log(`successful delete for ${image.name}`)
      setUrl('');
      setImage(null);
    }).catch(function(error) {
      // Uh-oh, an error occurred!
      console.log(error)
    });

  }

  const handleMessage = e => {
    setMessage(e.target.value);
    setDisabled(false);

    let newChars = e.target.value.length;
    let newProg = 0;
    setCharacterCount(newChars);
    if (newChars <= maxChars) {
      newProg = newChars;
    } else {
      newProg = maxChars;
    }
    if (newChars > maxChars) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    const finalProg = ((newProg / maxChars) * 100);
    setProgress((finalProg));
  }

  const handleWritersBlock = useCallback(() => {
    const min = 1;
    const max = writersBlockArray.length;
    const rand = Math.round(min + Math.random() * (max - min));
    // console.log(rand);

    const msg = writersBlockArray[(rand-1)] ?? '';
    setWritersBlock(msg);
  }, [])

  const showWritersBlock = () => {
    setWritersBlockBool(!writersBlockBool);
  }

  const showEmojiPicker = () => {
    setEmojiBool(!emojiBool);
  }

  const showGiphyPicker = () => {
    setGiphyBool(!giphyBool);
  }

  const handleEmoji = e => {

    // console.log(message);

    setMessage(message+e.native)

    // console.log(e.native);
  }

  const handleGiphy = item => {
    console.log(item);
    console.log(item.embed_url);
    setUrl(item.images.original.url);
    const newChars = (item.images.original.url);
    setMessage(newChars);
    setDisabled(false);
    setCharacterCount(newChars.length);
    const finalProg = ((newChars.length / maxChars) * 100);
    setProgress((finalProg));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage('');
    setProgress(0);
    setCharacterCount(0);
    setDisabled(false);
    setEmojiBool(false);
    setGiphyBool(false);
    if (image !== null) {
      deleteImage();
    } else {
      setUrl('');
    }
  };

  const handleSubmit = () => {

    let media = '';

    if (url !== '') {
      media = url;
    } else {
      const urls = extractUrls(message) ?? [];

      media = urls[0] ?? "";
    }

    setOpen(false);
    // console.log('addFuzzi: jarId = ', props.jarId);

    if (props.world) {
      dispatch(createFuzziAsync({'author': props.user.email ?? 'null','jarId': props.jarId, message, media }))
    } else {
      // console.log('addFuzzi: handleSubmit jarId = ', props.jarId);
      dispatch(createFuzziAsync({'author': props.user.email,'jarId': props.jarId, message, media}))

      dispatch(getFuzzisAsync(props.jarId));
    }
    setMessage('');
    setProgress(0);
    setCharacterCount(0);
    setDisabled(false);
    setUrl('');
  };

  useEffect(() => {
    handleWritersBlock();
}, [handleWritersBlock]);

  return (
    <div className={props.isJarManager ? 'is-jar-manager-add-to-jar' : ''}>
      <Button variant="contained" color="secondary" onClick={handleClickOpen} className={props.isJarManager ? "fuzzi-button" : ""}>{props.title}</Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{minWidth: "400px"}}>
          Add a Warm Fuzzi
        {/* <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            placeholder="Title"
            value={title}
            onInput={ e=>setTitle(e.target.value)}
            fullWidth
          /> */}
        </DialogTitle>
        <DialogContent>
          {url !== ''
            ? <div>
                <LazyLoadImage
                  alt='firebase-preview'
                  height={300}
                  src={url}
                  width={400} />
              </div>
            : <div></div>
          }
          {writersBlockBool
            ? <DialogContentText>
                <Tooltip title="roll the prompt">
                  <label htmlFor="dice">
                    <IconButton
                      // className={classes.faceImage}
                      color="primary"
                      aria-label="dice"
                      component="span"
                      onClick={handleWritersBlock}
                    >
                      <CasinoOutlinedIcon fontSize="default" />
                    </IconButton>
                  </label>
                </Tooltip>
                <b>{writersBlock}</b>
            </DialogContentText>
            : <div></div>
          }
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Message"
            type="text"
            multiline
            rows={6}
            rowsMax={10}
            value={message}
            onInput={ e=>handleMessage(e)}
            fullWidth
          />
          <Box style={{"width": "10vw", "display": "flex" }} >
            <Tooltip title="Select Image">
              <label htmlFor="faceImage">
                <IconButton
                  // className={classes.faceImage}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <ImageOutlinedIcon fontSize="large" />
                  <input
                      accept="image/*"
                      // className={classes.input}
                      id="faceImage"
                      type="file"
                      hidden
                      onChange={handleImage}
                    />
                </IconButton>
              </label>
            </Tooltip>
            {/* <label>{selectedFile ? selectedFile.name : "Select Image"}</label>. . . */}
            <Tooltip title="Writer's Block">
              <label htmlFor="writersBlock">
                <IconButton
                  // className={classes.faceImage}
                  color="primary"
                  aria-label="writers block"
                  component="span"
                  onClick={showWritersBlock}
                >
                  {writersBlockBool
                    ? <HelpOutlineIcon fontSize="large" />
                    : <HelpIcon fontSize="large" />
                  }
                </IconButton>
              </label>
            </Tooltip>
            <Tooltip title="Insert Emoji">
              <label htmlFor="emoji">
                <IconButton
                  // className={classes.faceImage}
                  color="primary"
                  aria-label="emoji"
                  component="span"
                  onClick={showEmojiPicker}
                >
                <InsertEmoticonIcon fontSize="large" />
                </IconButton>
              </label>
            </Tooltip>
            <Tooltip title="Giphy Picker">
              <label htmlFor="giphy">
                <IconButton
                  // className={classes.faceImage}
                  color="primary"
                  aria-label="giphy"
                  component="span"
                  onClick={showGiphyPicker}
                >
                <GifIcon fontSize="large" />
                </IconButton>
              </label>
            </Tooltip>
            {characterCount > 0 && characterCount < (maxChars - 50)
              ? <Tooltip title={`Character Limit: ${characterCount}/${maxChars}`}>
                  <label htmlFor="characters">
                    {/* <CircularProgress variant="determinate" value={progress} style={{'color': 'blue', height:'8px', width: '28px', float: 'right', marginTop: '25px', marginRight: '25px'}}/> */}
                    <CircularProgressWithLabel value={progress} charcount={characterCount} maxchars={maxChars} style={{'color': 'blue'  }} />
                  </label>
                </Tooltip>
              : characterCount >= (maxChars - 50) && characterCount <= maxChars
                ? <Tooltip title={`Character Limit: ${characterCount}/${maxChars}`}>
                    <label htmlFor="characters">
                      <CircularProgressWithLabel value={progress} charcount={characterCount} maxchars={maxChars} style={{'color': 'orange' }} />
                      {/* <CircularProgressWithLabel variant="determinate" value={progress} style={{'color': 'red', height:'8px', width: '28px', float: 'right', marginTop: '25px', marginRight: '25px'}}/> */}
                    </label>
                  </Tooltip>
                : characterCount > maxChars
                  ? <Tooltip title={`Character Limit: ${characterCount}/${maxChars}`}>
                    <label htmlFor="characters">
                      <CircularProgressWithLabel value={progress} charcount={characterCount} maxchars={maxChars} style={{'color': 'red' }} />
                      {/* <CircularProgressWithLabel variant="determinate" value={progress} style={{'color': 'red', height:'8px', width: '28px', float: 'right', marginTop: '25px', marginRight: '25px'}}/> */}
                    </label>
                  </Tooltip>
                  : <div></div>
            }
          </Box>
          {emojiBool 
            ? <div>
                <br></br>
                <Picker set='twitter' onSelect={handleEmoji} title='Pick your emoji…' emoji='point_up' showPreview={false} showSkinTones={false} style={{ marginBottom: '10px', marginLeft: '10px', width: '400px' }}/> 
              </div>
            : <div></div>
          }
          {giphyBool 
            ? <div style={{textAlign: 'center'}}>
                <br></br>
                <ReactGiphySearchbox
                  apiKey={process.env.REACT_APP_GIPHY_KEY}  // Required: get your on https://developers.giphy.com
                  onSelect={item => handleGiphy(item)}
                  masonryConfig={[{ columns: 3, imageWidth: 120, gutter: 5 }]}
                />
              </div>
            : <div></div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={disabled} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}