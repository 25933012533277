import { createSlice } from '@reduxjs/toolkit';
import { createFuzzi } from '../shared/firebase/firebase';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    activeBreakpoint: 3
  },
  reducers: {

    createFuzziReducer: (state, action) => {
      const message = action.payload.message ?? '';
      const jarId = action.payload.jarId ?? '';
      const author = action.payload.author ?? 'null'
      // console.log(title, message, media, uid);
      createFuzzi(message, author, jarId);
    },


    // setWorldJarState: (state, action) => {
    //   state.worldJar[action.payload.uid] = action.payload.data;
    // },
    setActiveBreakpoint: (state, action) => {
      console.log('windowSize: SLICE SETTING setActiveBreakpoint = ', action.payload);
      return ({...state, activeBreakpoint: action.payload})
    },

  },
});

export const { createFuzziReducer, setActiveBreakpoint } = homeSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


export const createFuzziAsync = (action) => async dispatch => {
  try {
    // console.log(action)
    await dispatch(createFuzziReducer(action));
  } catch (err) {

  }
};

// export const getWorldJarAsync = () => async dispatch => {
//   try {
//     const query = db.collection('worldJar').orderBy("media").limit(5);
//     const worldJarDict = {}

//     query.get()
//     .then(function(querySnapshot) {
//       querySnapshot.forEach(function(doc) {
//         console.log(doc.id, " => ", doc.data());
//         const uid = doc.id;
//         const data = doc.data();
//         worldJarDict[uid] = doc.data();
//         // dispatch(setWorldJarState({uid, data}));
//       });
//     }) 
//     .catch(function(error) {
//       console.log("Error getting documents: ", error);
//     });
//   } catch (err) {

//   }
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.profile.value)`
// export const selectUserProfile = state => state.profile.userProfile;

// export const selectWorldJar = state => state.home.worldJar;

export const selectActiveBreakpoint = state => state.home.activeBreakpoint;


export default homeSlice.reducer;
