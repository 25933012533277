import React, { useEffect } from 'react';
import { Button, TextField, DialogContent, Dialog, DialogTitle, DialogContentText, DialogActions, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { addCollaborators, addJarRecipient, editJarTitle, deleteCollaborator } from '../../../shared/firebase/firebase';
import { getJarAsync } from '../../DashboardSlice';
import { useDispatch, connect, useSelector } from 'react-redux';

const mapDispatch = { };

function FuzziManagerDialog(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
  
    const dialogType = props.dialogType;
    const jar = props.jar;


    const handleClickOpen = () => {
      setOpen(true);
      if (dialogType === 'recipient') {
          setValue(jar.recipient);
      } else if (dialogType === 'collaborators') {
        setValue('');
      } else if (dialogType === 'jarTitle') {
        setValue(jar.title);
      }
    };
    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        if (dialogType === 'recipient') {
            const lowerRecipient = value.toLowerCase();
            addJarRecipient(jar.jarId, lowerRecipient);
        } else if (dialogType === 'collaborators') {
            const lowerCollaborators = value.toLowerCase();
            addCollaborators(jar.jarId, lowerCollaborators);
        } else if (dialogType === 'jarTitle') {
            editJarTitle(jar.jarId, value);
        }
        if (dialogType !== 'collaborators') {
            setOpen(false);
        }
        console.log('getJar: (1) handleSubmit - jar = ', jar);
        dispatch(getJarAsync(jar.jarId));
        setValue('');
    }

    const handleDeleteCollaborator = (email) => {
        deleteCollaborator(jar.jarId, email);
        dispatch(getJarAsync(jar.jarId));
    }

    const dialogInfoDict = {
        recipient: {
            buttonText: 'Add Recipient +',
            title: 'Add a Recipient',
            description: "Enter a user's email to add them as the recipient for this jar"
        },
        collaborators: {
            buttonText: 'Add Collaborators +',
            title: 'Add Collaborators',
            description: "Enter a user's email to add them as a collaborator to this jar"
        },
        jarTitle: {
            title: 'Edit Jar Title',
            description: "Edit the title for this jar"
        }
    }


    const dialogInfo = dialogInfoDict[dialogType];
    const buttonText = dialogInfo.buttonText ? dialogInfo.buttonText : '';
    const title = dialogInfo.title ? dialogInfo.title : '';
    const description = dialogInfo.description ? dialogInfo.description : '';
    let isEdit = false;

    if (dialogType === 'collaborators') {
        isEdit = false;
    } else {
        if (props.value !== '') {
            isEdit = true;
        }
    }
    if (props.debug)  {
        console.log('isEdit: isEdit = ', isEdit);

    }

    return (
        <div className="dialog">
            { isEdit ? 
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    { dialogType === 'recipient' ?
                        <Typography variant="body2">{jar.recipient} </Typography> : null
                    }
                    <IconButton onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton> 
                </div> :
                <Button onClick={handleClickOpen}>{buttonText}</Button>
            }
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="add-user-dialog">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent className="dialog-content">
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                    { props.list ? 
                        <List >
                            { props.value.map((email) => {
                                return (
                                    <ListItem>
                                        <ListItemText
                                            primary={email}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={handleDeleteCollaborator.bind(this, email)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                            
                        </List> : null
                    }
                    
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label={dialogType === 'jarTitle' ? "Title" : "Email Address"}
                        type={dialogType === 'jarTitle' ? "text" : "email"} 
                        fullWidth
                        value={value}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        { isEdit ? 'Confirm' : 'Add' } 
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(null, mapDispatch)(FuzziManagerDialog);