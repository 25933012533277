import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteJarDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    setOpen(false);
    props.onConfirm();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      { props.isMoreActions ? 
        <ListItem button onClick={handleClickOpen} className="list-item">
          <ListItemIcon>
              { props.icon }
          </ListItemIcon>
          <ListItemText primary={props.action} />
        </ListItem> :
        <IconButton className="delete" onClick={handleClickOpen} >
            <DeleteIcon />
        </IconButton>
      }
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Jar
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this jar? It will be gone forever. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}