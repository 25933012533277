import { createSlice } from '@reduxjs/toolkit';
import { createFuzzi, createJar, getJars, getFuzzis, getJar, getUserById, updateUser, getJarsByCollaborator } from '../shared/firebase/firebase';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    jarsWorkingOn: [],
    currentJarFuzzis: [],
    jarsReceived: [],
    jarsGifted: [],
    activeJar: {},
    isViewingJar: false,
    isEditingJar: false,
    isFuzziAnimating: false,
    userInfo: {},
    giftedFuzzi: {},
    isMobile: false
  },
  reducers: {

    setGiftedFuzzi: (state, action) => {
      console.log('jarTest: (setGiftedFuzzi) giftedFuzzi = ', action.payload);
      return ({...state, giftedFuzzi: action.payload})
  },

    setIsFuzziAnimating: (state, action) => {
        return ({...state, isFuzziAnimating: action.payload})
    },

    setIsMobile: (state, action) => {
      return ({...state, isMobile: action.payload})
  },

    createFuzziReducer: (state, action) => {
      const message = action.payload.message ?? '';
      const media = action.payload.media ?? '';
      const jarId = action.payload.jarId ?? '';
      const author = action.payload.author ?? 'null'
      // console.log(title, message, media, uid);
      createFuzzi(message, author, media, jarId);
    },

    setGiftedJarsByCollaborator: (state, action) => {
      let jarsGifted = [...state.jarsGifted];
      let jarsWorkingOn = [...state.jarsWorkingOn];
      action.payload.forEach(jar => {
        if (jar.published) {
          jarsGifted.push(jar);
        } else {
          jarsWorkingOn.push(jar);
        }
      });

        return ({...state, jarsWorkingOn: jarsWorkingOn, jarsGifted: jarsGifted})
    },

    setGiftedJars: (state, action) => {
      let jarsGifted = [];
      let jarsWorkingOn = [];
      action.payload.forEach(jar => {
        if (jar.published) {
          jarsGifted.push(jar);
        } else {
          jarsWorkingOn.push(jar);
        }
      });

        return ({...state, jarsWorkingOn: jarsWorkingOn, jarsGifted: jarsGifted})
    },

    setJarsReceived: (state, action) => {
      let jarsReceived = [];
      action.payload.forEach(jar => {
        if (jar.published) {
          jarsReceived.push(jar);
        }
      });


      return ({...state, jarsReceived: jarsReceived})
    },

    setCurrentJarFuzzis: (state, action) => {
      console.log('jarTest: (4) (setCurrentJarFuzzis) fuzzis = ', action.payload);
        return ({...state, currentJarFuzzis: action.payload})
    },

    setActiveJar: (state, action) => {
      console.log('getJar: (4) setActiveJar - jar = ', action.payload);
      return ({ ...state, activeJar: action.payload })
    },

    setIsViewingJar: (state, action) => {
      return ({ ...state, isViewingJar: action.payload })
    },

    setIsEditingJar: (state, action) => {
      return ({ ...state, isEditingJar: action.payload })
    },

    setUserInfo: (state, action) => {
      return ({ ...state, userInfo: action.payload })
    },

    updateUserReducer: (state, action) => {
      const uid = action.payload.uid ?? 'null';
      const user = action.payload.user ?? {};

      updateUser(uid, user);
    },

  },
});

export const { setIsMobile, setGiftedFuzzi, createFuzziReducer, 
  setGiftedJars, setCurrentJarFuzzis, setJarsReceived, setActiveJar, 
  setIsViewingJar, setIsEditingJar, setIsFuzziAnimating, setUserInfo, updateUserReducer, 
  setGiftedJarsByCollaborator } = dashboardSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const getJarAsync = (jarId) => async dispatch => {
  var jar = await getJar(jarId);
  try {
    console.log('getJar: (3) getJarAsync - jar = ', jar);
    dispatch(setActiveJar(jar));
  } catch (err) {
    console.log('getJar: (3) getJarAsync - ERROR jar = ', jar);
  }
};

export const createFuzziAsync = (action) => async dispatch => {
    console.log('addFuzzi: createFuzziAsync action = ', action);
  try {
    // console.log(action)
    await dispatch(createFuzziReducer(action));
  } catch (err) {

  }
};

export const createJarAsync = (action) => async dispatch => {
    const author = action.author ?? '';
    const collaborators = action.collaborators ?? [''];
    const recipient = action.recipient ?? '';
    const title = action.title ?? `Untitled Jar`;

    var jar = await createJar(author, collaborators, recipient, title);
    console.log('createJar: (2) createJarAsync');
    try {
      // console.log(action)
      await dispatch(setActiveJar(jar));
      await dispatch(setIsViewingJar(true));
      await dispatch(setIsEditingJar(true));
    } catch (err) {
  
    }
};

export const getJarsByCollaboratorAsync = (email) => async dispatch => {
    var jars = await getJarsByCollaborator(email);
    try {
      await dispatch(setGiftedJarsByCollaborator(jars));
    } catch (err) {

    }
};


export const getJarsAsync = (userId, queryField) => async dispatch => {
    var jars = await getJars(userId, queryField);

    try {
      console.log('getJars: (3) getJarsAsync - jars = ', jars);
      // console.log(action)
      if (queryField === 'author') {
        await dispatch(setGiftedJars(jars));
      } else if (queryField === 'collaborators') {
        await dispatch(setGiftedJars(jars));
      } else if (queryField === 'recipient') {
        await dispatch(setJarsReceived(jars));
      }
    } catch (err) {
      console.log('getJars: (3) getJarsAsync ERRORR!!!!! - jars = ', jars);
    }
};

export const getFuzzisAsync = (jarId) => async dispatch => {
    var fuzzis = await getFuzzis(jarId);
    console.log('jarTest: (3) (getFuzzisAsync) fuzzis = ', fuzzis);

    try {
      await dispatch(setCurrentJarFuzzis(fuzzis));
    } catch (err) {
    }
};

export const getUserInfoAsync = (userId) => async dispatch => {
  // console.log('getFuzzis: (1.5) getFuzzisAsync running jarId = ', jarId);
  let user = await getUserById(userId);
  const t = user.createdAt ?? '';
  user.createdAt = t.toDate().toLocaleDateString();
  console.log('getUserInfo: (2) getUserInfoAsync userInfo = ', user);

  try {
    await dispatch(setUserInfo(user));
  } catch (err) {
      console.log(err);
  }
};

export const updateUserAsync = (action) => async dispatch => {
  console.log('editUser: editUserAsync action = ', action);
try {
  await dispatch(updateUserReducer(action));
} catch (err) {
  console.log(err);

}
};



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.profile.value)`
// export const selectUserProfile = state => state.profile.userProfile;

export const selectJarsWorkingOn = state => state.dashboard.jarsWorkingOn;

export const selectJarsReceived = state => state.dashboard.jarsReceived;

export const selectJarsGifted = state => state.dashboard.jarsGifted;

export const selectCurrentJarFuzzis = state => state.dashboard.currentJarFuzzis;

export const selectActiveJar = state => state.dashboard.activeJar;

export const selectIsViewingJar = state => state.dashboard.isViewingJar;

export const selectIsEditingJar = state => state.dashboard.isEditingJar;

export const selectIsFuzziAnimating = state => state.dashboard.isFuzziAnimating;

export const selectUserInfo = state => state.dashboard.userInfo;

export const selectGiftedFuzzi = state => state.dashboard.giftedFuzzi;

export const selectIsMobile = state => state.dashboard.isMobile;



export default dashboardSlice.reducer;
