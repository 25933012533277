import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { storage, auth } from '../../../index';
import './Profile.css';
import { getUserInfoAsync, updateUserAsync, selectUserInfo } from '../DashboardSlice';
import { useUser } from 'reactfire';
import {useHistory } from 'react-router-dom';
import defaultProfilePic from '../../../assets/defaultProfilePic.png'
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, 
    Typography, 
    Button, 
    Tooltip, 
    Dialog, 
    DialogActions,
    DialogContent,
    TextField,
    DialogTitle,  } from '@material-ui/core';

import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Profile(props) {

    const user = useUser() ?? { uid: 'null' };

    const dispatch = useDispatch();
    const history = useHistory();

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        dispatch(getUserInfoAsync(user.uid));
    }, [dispatch, user.uid])


    return (
        <div className="profile">
            <div className="header">
                <div className="left">
                    <div className="prof-pic">
                        <img src={userInfo.picture ?? defaultProfilePic} alt="" className="image"/>
                        < EditProfilePicture user={user} />
                        {/* <IconButton className="edit-pic">
                            <CameraAltIcon fontSize="large"/>
                        </IconButton> */}
                    </div>
                    <div className="username">
                        < EditDisplayName user={user} userInfo={userInfo} />
                    </div>
                </div>
                <div className="logout-date"> 
                    <Button variant="contained" color="secondary" className="logout" onClick={() => {
                        auth.signOut()
                        history.push('/login');
                    }} >
                        LOG OUT
                    </Button>
                    <Typography variant="h5">Joined: {userInfo.createdAt ?? ''}</Typography>
                </div>
            </div>
            <div className="divider"/>
            <div className="content">
                <Typography variant="subtitle1" align="left">Email</Typography>
                <Typography variant="body1" align="left">{user.email}</Typography>
            </div>
        </div>
    ); 
}



function EditProfilePicture(props) {

    const dispatch = useDispatch();

    // const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");


    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDisabled(false);
        if (url !== '') {
            // setImage(null);
            setUrl('');
        }
    };

    const handleImage = e => {
        if (url !== '') {
            // setImage(null);
            setUrl('');
        }
        if (e.target.files[0]) {
            const img = e.target.files[0]
            // setImage(e.target.files[0]);

            handleUpload(img);
        }
    };

    const handleUpload = (image) => {
        // setImage(image);
        const uid = props.user.uid ?? 'warmFuzzis';
        const uploadTask = storage.ref(`images/${uid}/profilePicture/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
            // const progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
            },
            error => {
            console.log(error);
            },
            () => {
            storage
                .ref(`images/${uid}/profilePicture`)
                .child(`${image.name}`)
                .getDownloadURL()
                .then(url => {
                setUrl(url);
                setDisabled(false);
                });
            }
        );
    };

    const handleSubmit = () => {

        const picture = url ?? '';

        const user = { picture };

        const uid = props.user.uid;
    
        setOpen(false);

        dispatch(updateUserAsync({uid, user}));

        setDisabled(false);
        setUrl('');
    };


    return (
        <div>
            <Tooltip title="Edit Profile Picture">
                <label htmlFor="faceImage"> 
                    <IconButton
                        // className={classes.faceImage}
                        className="edit-pic"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleClickOpen}
                    >
                        <CameraAltIcon className="edit-pic-icon" />
                    </IconButton>
                </label>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                Select a Profile Picture
                </DialogTitle>
                <DialogContent>
                    {url !== ''
                        ? <div>
                            <LazyLoadImage
                            className='image-preview'
                            alt='profile-picture-preview'
                            height={300}
                            src={url}
                            width={400} />
                        </div>
                        : <div></div>
                    }
                    <label htmlFor="faceImage">
                    <input
                        accept="image/x-png,image/jpeg"
                        // className={classes.input}
                        id="faceImage"
                        type="file"
                        // hidden
                        onChange={handleImage}
                    />
                    </label>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={disabled} color="primary">
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
}



function EditDisplayName(props) {
    const dispatch = useDispatch();
  
  
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
  
    const [name, setName] = useState('');
  
  
    const handleName = e => {
      setName(e.target.value);
  
      let newChars = e.target.value.length;
      let maxChars = 50;
      let minChars = 3;

      if (newChars <= maxChars && newChars >= minChars) {
          setDisabled(false);
      } else {
        setDisabled(true);
      }
    }

  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setName('');
      setDisabled(false);
    };
  
    const handleSubmit = () => {

  
      setOpen(false);
      // console.log('addFuzzi: jarId = ', props.jarId);

      const user = { name };

      const uid = props.user.uid;
    
      dispatch(updateUserAsync({uid, user}));

      setName('');
      setDisabled(false);
    };
  
    return (
      <div>
        <div className="edit-name-div">
            <Typography variant="h4">{props.userInfo.name ?? props.userInfo.email}</Typography>
            <Tooltip title="Edit Display Name">
                <IconButton
                    // className={classes.faceImage}
                    className="edit-name"
                    component="span"
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Edit Your Display Name
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Display Name"
              type="text"
              rows={1}
              rowsMax={1}
              value={name}
              onInput={ e=>handleName(e)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={disabled} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }