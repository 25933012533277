import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, Typography, ListItemIcon, ListItemText } from '@material-ui/core';

export default function ConfirmSendDialog(props) {
  const [open, setOpen] = React.useState(false);
  const jar = props.jar;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    setOpen(false);
    props.onConfirm();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      { props.isMoreActions ?
        <ListItem button onClick={handleClickOpen} className="list-item" disabled={props.disabled}>
          <ListItemIcon>
              { props.icon }
          </ListItemIcon>
          <ListItemText primary={props.action} />
        </ListItem> :
        <Button variant="contained" color="secondary" className="finish-and-send" disabled={props.disabled} onClick={handleClickOpen}>
          {"Finish & Send"}
        </Button>
      }
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ready to send?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Double check the contents of this jar before sending!
          </DialogContentText>
          <Typography variant="body1">
            Recipient: {jar.recipient}
          </Typography>
          { jar.collaborators.length > 0 ?
            <div>
                <Typography variant="body1">
                    Collaborators:
                </Typography>
                <List>
                    { jar.collaborators.map((collaboratorEmail) => {
                        return (
                            <Typography variant="body2">{collaboratorEmail}</Typography>
                        );
                    })}
                </List>
            </div> : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}