import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Avatar } from '@material-ui/core';
import fuzzisLogo from '../../../assets/fuzzisLogo.png';
import './DashboardAppBar.css';
import { selectIsMobile, selectIsViewingJar, setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar, selectIsEditingJar } from '../DashboardSlice';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector, connect } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreActionsDialog from './MoreActionsDialog';
import { getUserById } from '../../shared/firebase/firebase';
import { useUser } from 'reactfire';

const mapDispatch = { setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar };

export default connect (null, mapDispatch)(DashboardAppBar);
function DashboardAppBar(props) {

    const { setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar } = props;
    const reducers = { setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar };
    const isMobile = useSelector(selectIsMobile);
    const isViewingJar = useSelector(selectIsViewingJar);
    const isEditingJar = useSelector(selectIsEditingJar);
    const logoClassName = isMobile ? "fuzzis-logo-mobile" : "fuzzis-logo";
    const user = useUser() ?? { uid: 'null' };
    const [userInfo, setUserInfo] = React.useState({});

    const getUserInfoAsync = async() => {
        var userInfo = await getUserById(user.uid);
        try {
            setUserInfo(userInfo);
        } catch (err) {

        }
    };

    useEffect(() => {
        getUserInfoAsync();
    }, [])

    return (
        <div>
            { isMobile ? 
                <DashboardAppBarMobile 
                    classNameProp={props.class}
                    isViewingJar={isViewingJar}
                    isEditingJar={isEditingJar}
                    logoClassName={logoClassName}
                    handleOpen={props.handleOpen}
                    reducers={reducers}
                    userInfo={userInfo}
                    handleChangeTab={props.handleChangeTab}
                /> : 
                <DashboardAppBarDesktop 
                    classNameProp={props.class}
                    logoClassName={logoClassName}
                    userInfo={userInfo}
                    handleChangeTab={props.handleChangeTab}
                />
            }
        </div>
    );
}


function DashboardAppBarDesktop(props) {

    const { classNameProp, logoClassName, userInfo, handleChangeTab } = props;

    return (
        <AppBar position="fixed" className={classNameProp}>
            <Toolbar className="dashboard-appbar">
                <img src={fuzzisLogo} alt="" className={logoClassName}/>
                <a onClick={() => handleChangeTab(null, 4)} className="avatar">
                    <Avatar alt="" src={userInfo.picture} />
                </a>
            </Toolbar>
        </AppBar>
    );
}

function DashboardAppBarMobile(props) {
    const { classNameProp, isViewingJar, logoClassName, handleOpen, reducers, isEditingJar, userInfo } = props;

    const { setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar } = reducers;

    const handleBack = () => {
        setIsViewingJar(false);
        setCurrentJarFuzzis([]);
        setIsEditingJar(false);
    }

    return (
        <AppBar position="fixed" className={classNameProp}>
            <Toolbar className="dashboard-appbar">
                { isViewingJar ? 
                    <IconButton onClick={handleBack} className="left-icon">
                        <ArrowBackIcon />
                    </IconButton>  :
                    <IconButton onClick={handleOpen} className="left-icon">
                        <MenuIcon />
                    </IconButton> 
                }
                <img src={fuzzisLogo} alt="" className={logoClassName}/>
                { isEditingJar ?
                    <MoreActionsDialog handleBack={handleBack}/> :
                    null
                }
            </Toolbar>
        </AppBar>
    );
}