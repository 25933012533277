import fuzziJar from '../../../../../assets/fuzziJar.png';
import premiumJar from '../../../../../assets/premiumJar.png';
import customJar from '../../../../../assets/customJar.png';

const JarOptions = [
    {
        title: "Feel Good",
        valueProps: [
            "- Up to 50 Fuzzi's",
            "- 15 Collaborators",
            "- Free"
        ],
        src: fuzziJar,
    },
    {
        title: "Happy Gift",
        valueProps: [
            "- Up to 200 Fuzzi's",
            "- Unlimited Collaborators",
            "- $2.99"
        ],
        src: premiumJar,
        background: true,
        disabled: true
    },
    {
        title: "Custom",
        valueProps: [
            "- Create a unique",
            "experience for you",
            "or your team",
        ],
        src: customJar,
        disabled: true
    },
]

export default JarOptions;