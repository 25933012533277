import React from 'react';
import './HomeSection4.css';
import sqCartoon from '../../../assets/sqCartoon.png';
import collab from '../../../assets/collab.png';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectActiveBreakpoint } from '../homeSlice';

export default function HomeSection4(props) {

    const activeBreakpoint = useSelector(selectActiveBreakpoint);

    const flexDirection = activeBreakpoint >= 3 ? 'row' : 'column';

    return (
        <div className="home-section-4" style={{flexDirection: flexDirection}}>
            <div className="image-div">
                <img src={sqCartoon} alt="" className="image"/>
            </div>
            <div className="info-div">
                <img src={collab} alt="" className="image"/>
                <Typography variant="h2" color="primary" className="text">Collaborate with friends to <br/> create the perfect gift</Typography>
            </div>
        </div>
    );
}