import React, { useState, useEffect } from 'react';
import './Contact.css';
import {  useAuth } from 'reactfire';
import { makeStyles } from '@material-ui/core/styles';
import { useUser } from 'reactfire';
import {useHistory } from 'react-router-dom';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { sendContactEmail } from '../shared/firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, getUserInfoAsync } from '../Dashboard/DashboardSlice';

import { Typography, Button, TextField, Icon } from '@material-ui/core';
import '../../StyleSheet/GlobalStyling.css';




export default function Contact(props) {

    function ContactForm() {
        const useStyles = makeStyles((theme) => ({
            root: {
                textAlign: 'center'
            },
            textField: {
                paddingBottom: '30px',
            },
        }));

        const dispatch = useDispatch();
        const history = useHistory();
        const classes = useStyles();

        const user = useUser() ?? { uid: 'null' };

        const [body, setBody] = useState('');
        const [emailBool, setEmailBool] = useState(false);

        const userInfo = useSelector(selectUserInfo);

        useEffect(() => {
            dispatch(getUserInfoAsync(user.uid));
        }, [dispatch, user.uid])

        const handleBody = e => {
            setBody(e.target.value);
        }

        const handleSubmit = () => {
            if (body.length > 1) {
                sendContactEmail(userInfo.email, user.uid, userInfo.name, body);
                setEmailBool(true);
            }

            setBody('');
          };
    
        return (
            <div class={classes.root}>
                <label>
                    <Typography variant="h4" >Contact Us</Typography>
                    <Typography variant="subtitle2">Reach out if you are interested in a custom jar, notice any pesky bugs, or for general inquiries.</Typography>
                    <Typography variant="subtitle2">We will get back to you ASAP.</Typography>
                </label>
                <TextField
                    autoFocus
                    margin="none"
                    id="body"
                    label="Message"
                    type="text"
                    multiline
                    rows={6}
                    rowsMax={10}
                    name="body"
                    value={body}
                    onInput={ e=>handleBody(e)}
                    fullWidth
                    class={classes.textField}
                />
                {emailBool ? <Typography variant="h4" >Email Sent! <CheckCircleOutlineIcon /></Typography> : <div></div>}
                <Button variant="contained" color="secondary" className="submit" onClick={handleSubmit} >Submit</Button>

            </div>
        );
      }

  return (
    <div>
        <ContactForm />
    </div>
  );
}