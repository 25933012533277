import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    newUser: false
  },
  reducers: {
    flipBool: state => {
      state.newUser = !(state.newUser);
      console.log(`flip!!! ${state.newUser}`);
    },
    flipFalse: state => {
      state.newUser = false;
      console.log(`flip!!! ${state.newUser}`);
    },
  },
});

export const { flipBool, flipFalse } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectNewUser = state => state.auth.newUser;

export default authSlice.reducer;