import React from 'react';
import './Discover.css';


export default function Discover() {
    return (
        <div>
            <h1>Discover Page</h1>
        </div>
    );
}