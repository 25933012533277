import React from 'react';
import './FinalGetStarted.css';
import { Typography, Button } from '@material-ui/core';

export default function FinalGetStarted(props) {
    return (
        <div className="final-get-started">
            <div className="background" /> 
            <div className="content">
                <Typography variant="h2">You made it to the end! What are you <br/> waiting for? Send some love.</Typography>
                <Button href="/login" variant="contained" color="secondary" className="get-started">GET STARTED</Button>
            </div>
        </div>
    );
}