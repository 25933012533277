import React, { useEffect } from 'react';
import fuzzisLogo from '../../../assets/fuzzisLogo.png';
import { Typography, Button } from '@material-ui/core';
import './Splash.css';
import FlareComponent from 'flare-react';
import heartFloat from '../../../assets/Animations/heartFloat2.flr';
import HomeParticles from '../../../assets/Particles/HomeHearts';
import StarsParticle from '../../../assets/Particles/StarsParticle.json';
import Particles from 'react-particles-js';
import heart from '../../../assets/Particles/heart.png';
import SplashCircles from './SplashCircles';
import FuzziAnimation from '../../Dashboard/FuzziAnimation/FuzziAnimation';
import { selectIsFuzziAnimating, setIsFuzziAnimating } from '../../Dashboard/DashboardSlice';
import { connect, useSelector } from 'react-redux';
import { getFuzzis } from '../../shared/firebase/firebase';
import ReceiveFuzziWorldJar from '../../WorldJar/ReceiveFuzziWorldJar';
import useWindowSize from '../../../useWindowSize';

const mapDispatch = { setIsFuzziAnimating };

function Splash(props) {

    const windowSize = useWindowSize();

    const pronunciationVariant = windowSize.width <= 600 ? "h6" : "h5";
    const definitionVariant = windowSize.width <= 600 ? "h6" : "h4";

    return (
        <div className="splash">
            <img src={fuzzisLogo} alt="" className="logo"/>
            <SplashCircles/>
            <div className="login-buttons">
                <Button href="/login" className="login-button">SIGN UP</Button>
                <Button href="/login" className="login-button">LOG IN</Button>
            </div>
            <div className="splash-content">
                <div className="info-div">
                    <Typography variant="h1" className="header" align="left">Gift-giving redefined.</Typography>
                    <div className="subheader">
                        <div className="term">
                            <Typography variant="h3" align="left">Fuzzi</Typography>
                            <Typography variant={pronunciationVariant} align="left" className="pronunciation">(fuh-zee) n.</Typography>
                        </div>
                        <div className="definition">
                            <Typography variant={definitionVariant} align="left">: Feeling of happiness,</Typography>
                            <Typography variant={definitionVariant} align="left" className="definition-line2">contentment, or sentimentality</Typography>
                        </div>
                    </div>
                    {/* <Button variant="contained" color="secondary" className="get-started" onClick={handleOpenFuzzi}>OPEN A FUZZI</Button> */}
                    <ReceiveFuzziWorldJar href="/login" buttonText="OPEN A FUZZI" id="splash" confirmButtonText="Get Started" isGetStarted/>
                </div>
            </div>
        </div>
    );
}

export default connect(null, mapDispatch)(Splash);