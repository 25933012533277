import { Typography } from '@material-ui/core';
import React from 'react';
import FuzziCardList from '../FuzziCardList/FuzziCardList';
import './MyFuzziSection.css';

export default function MyFuzziSection(props) {
    return (
        <div className="my-fuzzi-section">
            <Typography variant="h3" align="left">{props.title}</Typography>
            <div className="section-divider"/>
            { props.jars.length > 0 ?
                <FuzziCardList role={props.role} jars={props.jars} isEditable={props.isEditable} /> :
                <Typography>{props.emptyMessage}</Typography>
            }
        </div>
    );
}