import React, { useEffect, useState } from 'react';
import './FuzziJarManager.css';
import { Paper, Typography, Button, IconButton } from '@material-ui/core';
import { useSelector, connect, useDispatch } from 'react-redux';
import { selectActiveJar, selectCurrentJarFuzzis, setIsViewingJar, setIsEditingJar, setCurrentJarFuzzis, getFuzzisAsync, selectIsFuzziAnimating, setIsFuzziAnimating, getJarsAsync, selectIsMobile } from '../../DashboardSlice';
import { AddToJar } from '../../../shared/AddToJar/AddToJar';
import { useUser } from 'reactfire';
import fuzziJar from '../../../../assets/fuzziJar.png';
import defaultProfilePic from '../../../../assets/defaultProfilePic.png';
import FuzziManagerDialog from '../JarDialogs/FuzziManagerDialog';
import Fuzzi from '../../Fuzzi/Fuzzi';
import { sendGiftEmail, publishJar, setOpenedFuzzi, deleteJar, getUserByEmail, getUserById } from '../../../shared/firebase/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FuzziAnimation from '../../FuzziAnimation/FuzziAnimation';
import DeleteJarDialog from '../JarDialogs/DeleteJarDialog';
import CollaboratorDialog from '../JarDialogs/CollaboratorDialog';
import ConfirmSendDialog from '../JarDialogs/ConfirmSendDialog';
import EditJarTitleDialog from '../JarDialogs/EditJarTitleDialog';
import EditJarRecipientDialog from '../JarDialogs/EditJarRecipientDialog';
import EditJarCollaboratorsDialog from '../JarDialogs/EditJarCollaboratorsDialog';


const mapDispatch = { setIsViewingJar, setCurrentJarFuzzis, setIsFuzziAnimating, setIsEditingJar };

function FuzziJarManager(props) {
    const dispatch = useDispatch();
    const user = useUser() ?? { uid: 'null' };

    const { setIsViewingJar, setCurrentJarFuzzis, setIsFuzziAnimating, setIsEditingJar } = props;

    const jar = useSelector(selectActiveJar);
    const currentJarFuzzis = useSelector(selectCurrentJarFuzzis);
    const [allFuzzisOpened, setAllFuzzisOpened] = React.useState(false);

    console.log('fuzzis: currentJarFuzzis = ', currentJarFuzzis);
    const jarCount = currentJarFuzzis.length;
    let openedCount = 0;

    currentJarFuzzis.forEach(jar => {
        if (jar.isOpened) {
            openedCount++;
        }
    });

    const handleDelete = () => {
        deleteJar(jar.jarId);
        dispatch(getJarsAsync(user.uid, "author"));
        handleBack();
    }

    const [giverInfo, setGiverInfo] = useState({});

    useEffect(() => {
        async function fetchGiverInfo() {
            const giver = await getUserById(user.uid);
            if (typeof giver != "undefined") {
                setGiverInfo(giver ?? {});
            }
        }
        fetchGiverInfo();
    
    }, [user.uid])


    const handleSend = () => {
        sendGiftEmail(jar.jarId, jar.recipient, user.uid, giverInfo.name, giverInfo.picture);
        publishJar(jar.jarId);
        setIsViewingJar(false);
        setIsEditingJar(false);
        setCurrentJarFuzzis([]);
    }

    const handleBack = () => {
        setIsViewingJar(false);
        setIsEditingJar(false);
        setCurrentJarFuzzis([]);
    }

    const handleOpenFuzzi = () => {
        console.log('openFuzzi: ======= ');
        const fuzzi = randomFuzzi();
        if (fuzzi !== null) {
            console.log('openFuzzi: fuzzi = ', fuzzi);
            setIsFuzziAnimating(true);
            setOpenedFuzzi(fuzzi.fuzziId, true);
            setAnimatedFuzzi(fuzzi);
            // dispatch(getFuzzisAsync(jar.jarId));
        }
    }

    function resetAllFuzzis() {
        currentJarFuzzis.forEach(fuzzi => {
            setOpenedFuzzi(fuzzi.fuzziId, false);
        });
        dispatch(getFuzzisAsync(jar.jarId));
    }

    function randomFuzzi() {
        var unopenedFuzzis = [];
        currentJarFuzzis.forEach(fuzzi => {
            if (!fuzzi.isOpened) {
                unopenedFuzzis.push(fuzzi);
            }
        });
        if (unopenedFuzzis.length === 1) { 
            setAllFuzzisOpened(true);
        } else if (unopenedFuzzis.length === 0) { 
            return null;
        } 

        const rand = Math.floor(Math.random() * Math.floor(unopenedFuzzis.length));
        const fuzzi = unopenedFuzzis[rand];

        return fuzzi;
    }

   

    // this 50 number will need to be dynamic based off jar 
    const fuzziMax = jar.fuzziMax ? jar.fuzziMax : 50;
    let fuzziCountText;
    if (!props.isEditable) {
        fuzziCountText = `${openedCount}/${jarCount}`;
    } else {
        if (jarCount >= fuzziMax - 5) {
            fuzziCountText = `${jarCount}/${fuzziMax}`;
        } else {
            fuzziCountText = jarCount;
        }
    }
    // const fuzziCountText = props.isEditable ? `${jarCount}/${fuzziMax}` : `${openedCount}/${jarCount}`;
    const fuzziSubtitleText = props.isEditable ? "Fuzzi's Added" : "Fuzzi's Opened";

    const isFuzziAnimating = useSelector(selectIsFuzziAnimating);
    const [animatedFuzzi, setAnimatedFuzzi] = React.useState({});

    const [sendEnabled, setSendEnabled] = React.useState(false);
    useEffect(() => {
        if (jar.recipient && jar.recipient !== '') {
            setSendEnabled(true);
        }
    }, [jar])


    const [recipientInfo, setRecipientInfo] = React.useState({});
    const [authorInfo, setAuthorInfo] = React.useState({});
    useEffect(() => {
        getUserInfoAsync();
    }, [])

    const getUserInfoAsync = async() => {
        var recipientInfo = await getUserByEmail(jar.recipient);
        var authorInfo = await getUserByEmail(jar.author);
        try {
            setRecipientInfo(recipientInfo);
            setAuthorInfo(authorInfo);
        } catch (err) {

        }
    };

    const isMobile = useSelector(selectIsMobile);

    useEffect(() => {
        var unopenedFuzzis = [];
        currentJarFuzzis.forEach(fuzzi => {
            if (!fuzzi.isOpened) {
                unopenedFuzzis.push(fuzzi);
            }
        });
        if (unopenedFuzzis.length === 0) {
            setAllFuzzisOpened(true);
        }
    }, [isFuzziAnimating])

    return (
        <div>
            { isMobile ?
                <FuzziJarManagerMobile 
                    user={user}
                    handleDelete={handleDelete}
                    jar={jar}
                    isEditable={props.isEditable}
                    isFuzziAnimating={isFuzziAnimating}
                    animatedFuzzi={animatedFuzzi}
                    handleBack={handleBack}
                    handleOpenFuzzi={handleOpenFuzzi}
                    fuzziCountText={fuzziCountText}
                    fuzziSubtitleText={fuzziSubtitleText}
                    currentJarFuzzis={currentJarFuzzis}
                    sendEnabled={sendEnabled}
                    handleSend={handleSend}
                    recipientInfo={recipientInfo}
                    authorInfo={authorInfo}
                    allFuzzisOpened={allFuzzisOpened}
                /> :
                <FuzziJarManagerDesktop 
                    user={user}
                    handleDelete={handleDelete}
                    jar={jar}
                    isEditable={props.isEditable}
                    isFuzziAnimating={isFuzziAnimating}
                    animatedFuzzi={animatedFuzzi}
                    handleBack={handleBack}
                    handleOpenFuzzi={handleOpenFuzzi}
                    fuzziCountText={fuzziCountText}
                    fuzziSubtitleText={fuzziSubtitleText}
                    currentJarFuzzis={currentJarFuzzis}
                    sendEnabled={sendEnabled}
                    handleSend={handleSend}
                    recipientInfo={recipientInfo}
                    authorInfo={authorInfo}
                    allFuzzisOpened={allFuzzisOpened}
                /> 
            }
        </div>
        
    );
}

function FuzziJarManagerDesktop(props) {
    const { user, handleDelete, jar, isEditable, isFuzziAnimating, 
        animatedFuzzi, handleBack, handleOpenFuzzi, fuzziCountText, 
        fuzziSubtitleText, currentJarFuzzis, sendEnabled, handleSend, recipientInfo, authorInfo, allFuzzisOpened } = props;

    let fuzziButton = null;

    if (isEditable) {
        fuzziButton = <AddToJar user={user} title="ADD FUZZI" jarId={jar.jarId} isJarManager/>;
    } else {
        if (jar.recipient === user.email) {
            fuzziButton = <Button variant="contained" color="secondary" className="fuzzi-button" onClick={handleOpenFuzzi} disabled={allFuzzisOpened}>OPEN FUZZI</Button>; 
        }
    }

    return (
        <div className="fuzzi-jar-manager">
            <Paper className="container" elevation={3}>
                { isEditable && user.email === jar.author ?
                        <DeleteJarDialog className="delete" onConfirm={handleDelete}/> :
                    null
                }
                { isFuzziAnimating ?
                    <FuzziAnimation fuzzi={animatedFuzzi} getFuzzisOnConfirm/> : null
                }
                <IconButton onClick={handleBack} className="back-button">
                    <ArrowBackIcon size="large"/>
                </IconButton>
                <div className="info-div">
                    { fuzziButton }
                    <img src={fuzziJar} alt="" className="jar"/>
                    <div className="fuzzis-opened">
                        <Typography variant="h3" align="center">{fuzziCountText}</Typography>
                        <Typography variant="h6">{fuzziSubtitleText}</Typography>
                    </div>
                </div>
                <div className="content">
                    <div className="title-div">
                        <div className="title-flex">
                            { isEditable ?
                                <EditJarTitleDialog jar={jar} /> : 
                                <Typography variant="h3">{jar.title}</Typography>
                            }
                        </div>
                        <div className="divider" />
                    </div>
                    <div className="messages">
                        { currentJarFuzzis.length > 0 ?
                            <div>
                                { currentJarFuzzis.map((fuzzi) => {
                                    return (
                                        <Fuzzi fuzzi={fuzzi} isEditable={isEditable}/>
                                    );
                                })}
                            </div> : <Typography>No fuzzi's have been added to this jar yet</Typography>
                        }
                        
                    </div>
                </div>
                <div className="footer">
                    { isEditable ?
                        <EditableFooter jar={jar} sendEnabled={sendEnabled} handleSend={handleSend} recipientInfo={recipientInfo} user={user}/> :
                        <GiftedFooter jar={jar} authorInfo={authorInfo}/>
                    }
                </div>
            </Paper>
        </div>
    );
}

function FuzziJarManagerMobile(props) {
    const { user, handleDelete, jar, isEditable, isFuzziAnimating, 
        animatedFuzzi, handleBack, handleOpenFuzzi, fuzziCountText, 
        fuzziSubtitleText, currentJarFuzzis, sendEnabled, handleSend, recipientInfo, authorInfo, allFuzzisOpened } = props;

    let fuzziButton = null;

    if (isEditable) {
        fuzziButton = <AddToJar user={user} title="ADD FUZZI" jarId={jar.jarId} isJarManager/>;
    } else {
        if (jar.recipient === user.email) {
            fuzziButton = <Button variant="contained" color="secondary" className="fuzzi-button" onClick={handleOpenFuzzi} disabled={allFuzzisOpened}>OPEN FUZZI</Button>; 
        }
    }

    return (
        <div className="fuzzi-jar-manager-mobile">
            <div className="container">
                <div className="header">
                    { isEditable ? 
                        <EditJarTitleDialog jar={jar} /> :
                        <Typography variant="h3">{jar.title}</Typography>
                    }
                    { isFuzziAnimating ?
                        <FuzziAnimation fuzzi={animatedFuzzi} getFuzzisOnConfirm/> : null
                    }
                    <div className="subheader">
                        { fuzziButton }
                        <div className="subheader-info">
                            <img src={fuzziJar} alt="" className="jar"/>
                            <div className="fuzzis-opened">
                                <Typography variant="h3" align="center">{fuzziCountText}</Typography>
                                <Typography variant="h5">{fuzziSubtitleText}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    { currentJarFuzzis.length > 0 ?
                        <div>
                            { currentJarFuzzis.map((fuzzi) => {
                                return (
                                    <Fuzzi fuzzi={fuzzi} isEditable={isEditable}/>
                                );
                            })}
                        </div> : <Typography>No fuzzi's have been added to this jar yet</Typography>
                    }
                </div>
                <div className="footer">
                    { isEditable ?
                        <div className="recipient">
                            <img src={recipientInfo.picture ?? defaultProfilePic} alt="" className="prof-pic"/>
                            <EditJarRecipientDialog jar={jar}/>
                        </div> : 
                        <GiftedFooter jar={jar} authorInfo={authorInfo}/>
                    }
                    { isEditable ? <div className="vertical-divider" /> : null }
                    { isEditable ? <EditJarCollaboratorsDialog jar={jar}/> : null }
                </div>
            </div>
        </div>
    );
}

function EditableFooter(props) {
    const jar = props.jar;
    const user = props.user;
    const recipientInfo = props.recipientInfo;

    return (
        <div className="footer-content">
            <div className="users-info">
                <div className="recipient">
                    <img src={recipientInfo.picture ?? defaultProfilePic} alt="" className="prof-pic"/>
                    <EditJarRecipientDialog jar={jar}/>
                </div>
                <div className="vertical-divider" />
                <EditJarCollaboratorsDialog jar={jar}/>
            </div>
            { user.email === jar.author ?
                <ConfirmSendDialog jar={jar} onConfirm={props.handleSend} disabled={!props.sendEnabled}/> : null
            }
        </div>
    );
}

function GiftedFooter(props) {

    const jar = props.jar;
    const authorInfo = props.authorInfo;
    var allCollaborators = [];
    allCollaborators.push(jar.author);
    jar.collaborators.forEach(collaborator => {
        allCollaborators.push(collaborator);
    });

    return (
        <div className="footer-content">
            <div className="users-info">
                <div className="recipient">
                    <img src={authorInfo.picture ?? defaultProfilePic} alt="" className="prof-pic"/>
                    <div className="collaborators">
                        { allCollaborators.length > 1 ?
                            <CollaboratorDialog isJarManager variant="body2" text={`${allCollaborators[0]} and ${allCollaborators.length - 1} others...`} collaborators={allCollaborators} /> :
                            <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <Typography variant="subtitle2" className="footer-text">{allCollaborators[0]}</Typography>
                                <Typography variant="body2" align="left" className="edit-button-label">Author</Typography>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}




export default connect(null, mapDispatch)(FuzziJarManager);