import React from 'react';
import './HomeFooter.css';
import { Typography } from '@material-ui/core';
import fuzzisLogo from '../../../assets/fuzzisLogo.png';

export default function HomeFooter(props) {
    return (
        <div className="home-footer">
            <div className="background" /> 
            <div className="content">
                <img src={fuzzisLogo} alt="" className="footer-logo"/>
                <Typography variant="h5">2020 Warm Fuzzis All rights reserved.</Typography>
                <Typography variant="h5">Made in the USA</Typography>
            </div>
        </div>
    );
}