const StyleSheetColors = {
    primary: '#FF7079',
    primaryAlphaDown: 'rgba(255, 112, 121, 0.2)',
    secondary: '#70A9FF',
    cardBackgroundPrimary: '#FFF3F3',
    cardBackgroundSecondary: '#FFC2C2',
    drawerBackground: '#363740',
    textPrimary: '#000000',
    textSecondary: '#A4A6B3',
    error: '#bf1650'
}

export default StyleSheetColors;