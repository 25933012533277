import React from 'react';
import './Jars.css';


export default function Jars() {
    return (
        <div>
            <h1>My Jars Page</h1>
        </div>
    );
}