import React from 'react';
import './SplashCircles.css';

export default function SplashCircle(props) {


    const style1 = {
        width: '300px',
        height: '300px',
        position: 'absolute',
        top: '-100px',
        right: '-50px',
        background: '#39A0FF'
    }

    const styleDict = {
        '1':style1
    }

    const style = styleDict[props.index];


    return (
        <div className="splash-circles">
            <div className="splash-circle1"/>
            <div className="splash-circle2"/>
            <div className="splash-circle3"/>
            <div className="splash-circle4"/>
        </div>
    );
}