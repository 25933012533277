//add fuzzi objects

export const writersBlockArray = ['What is the last thing you did with this person?',
     'What do you love about this person?',
     'What is something they are good at doing?',
     'Tell a funny story.',
     'Share a song that reminds you of this person.',
     'Share the best meme you saw today.',
     'Where did you meet this person?',
     'What is unique about this person?',
     'What is something you could do together?',
     'What is the one thing you wish you could tell this person?',
     'How do you feel when this person walks into the room?',
     'Try to make them laugh.',
     'Share an old memory.',
     'Share an old photo of this person.',
     'Share an instagram post that reminds you of this person.',
     'Have any interesting world events occurred recently?'
  ]

export const maxChars = 300;


export const defaultPics = [
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal1.png?alt=media&token=41ba89e3-0585-4e61-b1b3-6d4f31359bfc',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal2.png?alt=media&token=b1a436c0-a068-454d-96e3-6dc3beb74f1b',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal3.png?alt=media&token=fe256ba8-a470-4334-bf85-dde255d11996',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal4.png?alt=media&token=f8a75be8-cf2d-439a-a7b6-9992ee27def9',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal5.png?alt=media&token=d178d108-85ef-41a7-b3b5-35d1cdffa081',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal6.png?alt=media&token=b2895085-1077-4603-89cb-2eff5e0bab5b',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal7.png?alt=media&token=fc4477a0-11fe-49e0-a2d3-292dcce99fae',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal8.png?alt=media&token=851d1a11-6ae2-4bb3-b294-8c3e174834a5',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal9.png?alt=media&token=f631a1b3-b9da-470f-a77f-71582353c797',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal10.png?alt=media&token=2e779bf0-b0c0-4861-ad14-23fdfc1037fb',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal11.png?alt=media&token=fa58a241-66cc-4b63-9a0b-2139a86c14c5',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal12.png?alt=media&token=838eebc9-898a-4c61-8769-8bf4ca3405a4',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal13.png?alt=media&token=983b7684-aead-4457-8a86-e48cadef9b42',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal14.png?alt=media&token=b06df392-24cc-407b-bece-25f09b39dbfa',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal15.png?alt=media&token=feaa71a6-261e-4184-9757-dbefdcb54761',
  'https://firebasestorage.googleapis.com/v0/b/warm-fuzzies-dev.appspot.com/o/images%2Fdefault%2FprofAnimal16.png?alt=media&token=a9f2d2a3-8e22-48e6-8231-cb85f92eeaba'
];