import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemIcon, ListItemText, DialogTitle, Dialog, Typography, IconButton, DialogContent } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import './DashboardAppBar.css';
import ConfirmSendDialog from '../FuzziJarPage/JarDialogs/ConfirmSendDialog';
import DeleteJarDialog from '../FuzziJarPage/JarDialogs/DeleteJarDialog';
import { sendGiftEmail, publishJar, deleteJar, getUserById } from '../../shared/firebase/firebase';
import { selectActiveJar, getJarsAsync } from '../DashboardSlice';
import { useUser } from 'reactfire';
import { useSelector, useDispatch } from 'react-redux';

const actions = ['Send', 'Delete'];
const actionIcons = [<SendIcon />, <DeleteIcon/>]

function MoreActionsDialogElement(props) {

  const [sendEnabled, setSendEnabled] = React.useState(false);
  
  const handleClose = () => {
    props.onClose();
  };

  const handleSend = () => {
    props.handleSend();
    props.onClose();
  }

  const handleDelete = () => {
    props.handleDelete();
    props.onClose();
  }

  useEffect(() => {
    if (props.jar.recipient && props.jar.recipient !== '') {
      setSendEnabled(true);
    }
  }, [props.jar])


  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open} >
      <DialogContent className="more-actions-dialog">
        <DialogTitle id="simple-dialog-title">More Actions</DialogTitle>
        <List>
            <ConfirmSendDialog isMoreActions jar={props.jar} action={"Send"} icon={actionIcons[0]} onConfirm={handleSend} disabled={!sendEnabled}/>
            <DeleteJarDialog isMoreActions jar={props.jar} action={"Delete"} icon={actionIcons[1]} onConfirm={handleDelete}/>
            <ListItem autoFocus button onClick={handleClose} >
                <ListItemIcon>
                    <CancelIcon />
                </ListItemIcon>
                <ListItemText primary="Cancel" />
            </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}


export default function MoreActionsDialog(props) {

  const user = useUser() ?? { uid: 'null' };
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const jar = useSelector(selectActiveJar);

  const [giverInfo, setGiverInfo] = React.useState({});

  useEffect(() => {
      async function fetchGiverInfo() {
          const giver = await getUserById(user.uid);
          if (typeof giver != "undefined") {
              setGiverInfo(giver ?? {});
          }
      }
      fetchGiverInfo();
  
  }, [user.uid])

  const handleSend = () => {
      sendGiftEmail(jar.jarId, jar.recipient, user.uid, giverInfo.name, giverInfo.picture);
      publishJar(jar.jarId);
      props.handleBack();
  }

  const handleDelete = () => {
      deleteJar(jar.jarId);
      dispatch(getJarsAsync(user.uid, "author"));
      props.handleBack();
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen} className="right-icon" >
        <MoreHorizIcon />
      </IconButton>
      <MoreActionsDialogElement jar={jar} open={open} onClose={handleClose} handleDelete={handleDelete} handleSend={handleSend}/>
    </div>
  );
}
