import { createMuiTheme } from '@material-ui/core/styles';
import StyleSheetColors from './StyleSheet/StyleSheetColors';
// import useWindowSize from './useWindowSize';

export default function MuiTheme() {

    const Mulish = "'Mulish', sans-serif;";
    const ManRope = "'ManRope', sans-serif;";

    const theme = createMuiTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        shape: {
          borderRadius: 16
        },
        palette: {
          primary: {
            main: StyleSheetColors.primary
          },
          secondary: {
            main: StyleSheetColors.secondary
          },
          error: {
            main: StyleSheetColors.error
          },
          drawer: {
            main: StyleSheetColors.drawerBackground
          },
          text: {
            primary: StyleSheetColors.textPrimary,
            secondary: StyleSheetColors.textSecondary
          }
        },
        typography: {
          fontFamily: ManRope,
          h1: {
            fontSize: '5.5vw',
            fontWeight: 800
          },
          h2: {
            fontSize: '3.5vw',
            fontWeight: 800
          },
          h3: {
            fontSize: '2.5vw',
            fontWeight: 800
          },
          h4: {
            fontSize: '2vw',
            fontWeight: 800
          },
          h5: {
            fontSize: '1.8vw',
            fontWeight: 700
          },
          h6: {
            fontSize: '1.5vw',
            fontWeight: 550
          },
          subtitle1: {
            fontWeight: 600,
            fontSize: '14px'
          },
        },
      
      }) 

      return theme;
}