import React, { Suspense } from 'react';
import './Nav.css';
import { Link, NavLink } from 'react-router-dom';
import { Typography, Button, AppBar, Box } from '@material-ui/core';
import fuzzisLogo from '../../assets/fuzzisLogo.png';
import { AuthCheck } from 'reactfire';


export default function Nav() {
    return (
        <Suspense fallback={'loading warm fuzzy user'}>
            <AuthCheck fallback={<NavBar />}>
                {/* <NavAuth /> */}
                <NavBar />
            </AuthCheck>
        </Suspense>
    );
}

function NavBar() {
    return (
        <nav className="app-bar">
            <Box flexDirection="row" display="flex" justifyContent="space-between">
                <Link className="home-link" to="/">
                    <div className="logo">
                        <img src={fuzzisLogo} alt=""/>
                    </div>
                </Link>
                {/* <Button href="/dashboard" className="dashboard-button" variant="contained" color="secondary">Dashboard</Button> */}
            </Box>
        </nav>
    );
}

function NavNoAuth() {
    return ( 
        <nav className="navbar">
            <h1>
            <Link to="/">
                {/* <img className="navbar-logo" src={navLogo} alt=""/> */}
                Warm Fuzzies
            </Link>
            </h1>
            <ul className="navbar-nav">
                <li>
                    <NavLink exact activeClassName="active-link" to="/jars">
                        My Jars
                    </NavLink>
                </li>
                <li>
                    <NavLink exact activeClassName="active-link" to="/discover">
                        Discover
                    </NavLink>
                </li>
            </ul>
            <ul className="navbar-nav-login">
                <li>
                    <NavLink exact activeClassName="active-link" to="/login">
                        SIGN IN
                    </NavLink>
                </li>
                <li type="get-started">
                    <Link to="/login" type="get-started">
                        GET STARTED
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

function NavAuth() {
    return ( 
        <nav className="navbar">
            <h1>
            <Link to="/">
                {/* <img className="navbar-logo" src={navLogo} alt=""/> */}
                Warm Fuzzies
            </Link>
            </h1>
            <ul className="navbar-nav">
                <li>
                    <NavLink exact activeClassName="active-link" to="/news">
                        My Jars
                    </NavLink>
                </li>
                <li>
                    <NavLink exact activeClassName="active-link" to="/portfolio">
                        Discover
                    </NavLink>
                </li>
            </ul>
            <ul className="navbar-nav-login">
                <li>
                    <NavLink exact activeClassName="active-link" to="/profile" >
                        PROFILE
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
