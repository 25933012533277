import React, { useEffect } from 'react';
import './GiftFuzziJars.css';
import MyFuzziSection from '../MyFuzziSection/MyFuzziSection';
import JarOption from './JarOption/JarOption';
import jarOptions from './JarOption/JarOptions';
import { getJarsAsync, selectJarsWorkingOn, getJarsByCollaboratorAsync } from '../../DashboardSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useUser } from 'reactfire';

export default function GiftFuzziJars(props) {
    const dispatch = useDispatch();
    const user = useUser() ?? { uid: 'null' };

    const jarsWorkingOn = useSelector(selectJarsWorkingOn);


    useEffect(() => {
        dispatch(getJarsAsync(user.email, "author"));
        dispatch(getJarsByCollaboratorAsync(user.email));
    }, [])

    return (
        <div className="gift-fuzzi-jars">
            <div className="jar-options"> 
                { jarOptions.map((details) => {
                    return (
                        <JarOption details={details}/>
                    );
                })}
            </div>
            <MyFuzziSection title={"Jars I'm working on"} jars={jarsWorkingOn} isEditable emptyMessage="Choose an option above to start making your first jar!"/>
        </div>
    );
}