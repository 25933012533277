import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Typography, Tabs, Divider, Tab, Box } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import RedeemIcon from '@material-ui/icons/Redeem';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import './Dashboard.css';
import LoginComponent from '../Auth/Auth';
import { Redirect } from 'react-router-dom';
import Profile from './Profile/Profile';
import Contact from '../Contact/Contact';
import { AuthCheck } from 'reactfire';
import '../../StyleSheet/CSSColors.css';
import StyleSheetColors from '../../StyleSheet/StyleSheetColors.js';
import '../../StyleSheet/GlobalStyling.css';
import WorldJar from '../WorldJar/WorldJar';
import DashboardAppBar from './DashboardAppBar/DashboardAppBar';
import FuzziJarPage from './FuzziJarPage/FuzziJarPage';
import { setIsViewingJar, setCurrentJarFuzzis, selectIsFuzziAnimating, setIsMobile, selectIsMobile, setIsEditingJar } from './DashboardSlice';
import { connect, useSelector } from 'react-redux';
import useWindowSize from '../../useWindowSize';
import TestFuzziAnimation from './FuzziAnimation/TestFuzziAnimation';

const drawerWidth = 240;

const orientationOptions = { 
  message: 'you are making me dizzy! Rotate your device back to portrait mode.',
  color:  "#000000",
  animation: false,
  fontSize: 5
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.drawer.main,
    color: theme.palette.text.secondary,
    paddingTop: '20px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    paddingTop: '70px'
  },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function CustomTab(props) {
    return (
        <div className="custom-tab">
            <div className="custom-tab-icon">
                { props.icon }
            </div>
            <Typography className="custom-tab-text">{props.title}</Typography>
        </div>
    );
}

const mapDispatch = { setIsViewingJar, setCurrentJarFuzzis, setIsMobile, setIsEditingJar };

export default connect(null, mapDispatch)(Dashboard);
function Dashboard(props) {

  const { setIsViewingJar, setCurrentJarFuzzis, setIsMobile, setIsEditingJar } = props;
  const reducers = { setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar };

  const windowSize = useWindowSize();
  const isMobile = useSelector(selectIsMobile);

  /* MOBILE MEDIA */
  if (windowSize.width <= 1200) {
    if (!isMobile) {
      setIsMobile(true);
    }
  } else {
    if (isMobile) {
      setIsMobile(false);
    }
  }

  return (
      <Suspense fallback={''}>
          <DashboardCheck reducers={reducers}/>
      </Suspense>
  );

}

function DashboardCheck(props) {

  return (
    <div className="authForm">
      <AuthCheck fallback={<Redirect to="/login"/> }>
        <DashboardMain reducers={props.reducers}/>
      </AuthCheck>
    </div>

  );
}


function DashboardMain(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [open, setOpen] = React.useState();
  const isMobile = useSelector(selectIsMobile);

  const { setIsViewingJar, setCurrentJarFuzzis, setIsEditingJar } = props.reducers;

  const handleChange = (event, newValue) => {
    if (isMobile) {
      handleClose();
    }
    setValue(newValue);
    console.log('fuzziPage: (1) SETTING isViewingJar to false');
    setIsViewingJar(false);
    setCurrentJarFuzzis([]);
    setIsEditingJar(false);
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div className="dashboard">
        <DashboardAppBar class={classes.appBar} handleOpen={handleOpen} handleChangeTab={handleChange} />
        <Drawer
            className={classes.drawer}
            variant={isMobile ? "" : "permanent"}
            open={isMobile ? open : true}
            onClose={isMobile ? handleClose : null}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar} />
            <Tabs
                TabIndicatorProps={{
                    style: {
                        width:"240px",
                        zIndex: -1,
                        background: StyleSheetColors.primaryAlphaDown
                    }
                }}
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label={<CustomTab title="Community Fuzzi's" icon={<PeopleIcon />}/>} {...a11yProps(0)} />
                <Tab label={<CustomTab title="My Fuzzi Jars" icon={<RedeemIcon /> }/>} {...a11yProps(1)} />
                <Tab label={<CustomTab title="Create a Jar" icon={<LibraryAddIcon />}/>} {...a11yProps(2)} />
                <div className="divider" >
                    <Divider />
                </div>
                <Tab label={<CustomTab title="Profile" icon={<PersonOutlineIcon />}/>} {...a11yProps(3)} />
                <Tab label={<CustomTab title="Contact" icon={<ContactMailOutlinedIcon />}/>} {...a11yProps(4)} />
                {/* <Tab label={<CustomTab title="Test" icon={<ContactMailOutlinedIcon />}/>} {...a11yProps(5)} /> */}
            </Tabs>
        </Drawer>
        <main className="main">
          <TabPanel value={value} index={0}>
            <WorldJar textColor="black"/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FuzziJarPage page="myFuzzis"/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FuzziJarPage page="giftFuzzis"/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Profile />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Contact />
          </TabPanel>
          {/* <TabPanel value={value} index={6}>
            <TestFuzziAnimation/>
          </TabPanel> */}
        </main>
    </div>
  );
}

