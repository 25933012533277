import React from 'react';
import './FuzziCardList.css';
import FuzziCard from '../FuzziCard/FuzziCard';
import { TablePagination, Slide } from '@material-ui/core';
import useWindowSize from '../../../../useWindowSize';

export default function FuzziCardList(props) {
    const [page, setPage] = React.useState(0);
    const [slideDirection, setSlideDirection] = React.useState('left');
  
    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setSlideDirection("left");
        } else {
            setSlideDirection("right");
        }
        setPage(newPage);
    };

    const windowSize = useWindowSize();

    const maxWidth2 = 860;
    const maxWidth3 = 1148;
    const maxWidth4 = 1510;

    const cardCount = props.jars.length;
    const [maxCardsPerPage, setMaxCardsPerPage] = React.useState(4);

    // if (windowSize.width < maxWidth2 && maxCardsPerPage !== 2) {
    //     setMaxCardsPerPage(2);
    // } else if (windowSize.width < maxWidth3 && maxCardsPerPage !== 3 && cardCount >= 3) {
    //     setMaxCardsPerPage(3);
    // } else if (windowSize.width < maxWidth4 && maxCardsPerPage !== 4 && cardCount >= 4) {
    //     setMaxCardsPerPage(4);
    // } else if (windowSize.width >= maxWidth4 && maxCardsPerPage !== 5 && cardCount >= 5) {
    //     setMaxCardsPerPage(5);
    // }

    let cardsPageDict = {};
    let pageCounter = 0;
    props.jars.forEach((card, index) => {
        if (index % maxCardsPerPage === 0 && index !== 0) {
            pageCounter++;
        }
        if (!cardsPageDict[pageCounter]) {
            cardsPageDict[pageCounter] = [card];
        } else {
            cardsPageDict[pageCounter].push(card);
        }
    });


    console.log('cards: cardsPageDict FINAL = ', cardsPageDict);

    return (
        <div className="fuzzi-card-list">
            { Object.keys(cardsPageDict).map((pageNum) => {
                console.log('cards: pageNum = ', pageNum);
                console.log('cards: page = ', page);
                if (parseInt(pageNum) === page) {
                    return (
                        <Slide in direction={slideDirection} unmountOnExit mountOnEnter>
                            <div className="list">
                                { cardsPageDict[page].map((card) => {
                                    return (
                                        <FuzziCard role={props.role} jar={card} isEditable={props.isEditable}/>
                                    );
                                })}
                            </div>
                        </Slide>
                    );
                } else {
                    return null;
                }
                
            })}
            <TablePagination
                component="div"
                count={cardCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={maxCardsPerPage}
                rowsPerPageOptions={[]}
            />
        </div>
    );
}

// { Object.keys(cardsPageDict).map((pageNum) => {
//     console.log('cards: pageNum = ', pageNum);
//     console.log('cards: page = ', page);
//     return (
//         <Slide in={parseInt(pageNum) === page} direction={slideDirection} unmountOnExit mountOnEnter timeout={{enter: 2000, exit: 2000}}>
//             <div className="list">
//                 { cardsPageDict[page].map((card) => {
//                     return (
//                         <FuzziCard title={card}/>
//                     );
//                 })}
//             </div>
//         </Slide>
//     );
    
// })}