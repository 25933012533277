import React, { useEffect } from 'react';
import './FuzziCard.css';
import { Paper, Typography, Button, Slide, IconButton } from '@material-ui/core';
import fuzziJar from '../../../../assets/fuzziJar.png';
import defaultJar from '../../../../assets/defaultJar.png';
import giftedJar from '../../../../assets/giftedJar.png';
import defaultProfilePic from '../../../../assets/defaultProfilePic.png';
import DeleteIcon from '@material-ui/icons/Delete';
import { getFuzzis, getUserById, getUserByEmail } from '../../../shared/firebase/firebase';
import { getJarsAsync, setIsViewingJar, setActiveJar, setCurrentJarFuzzis, setIsEditingJar } from '../../DashboardSlice';
import { useUser } from 'reactfire';
import { useDispatch, useSelector, connect } from 'react-redux';
import CollaboratorDialog from '../JarDialogs/CollaboratorDialog';

const mapDispatch = { setIsViewingJar, setActiveJar, setCurrentJarFuzzis, setIsEditingJar };

function FuzziCard(props) {
    const dispatch = useDispatch();
    const { setIsViewingJar, setActiveJar, setCurrentJarFuzzis, setIsEditingJar } = props;
    const jar = props.jar;

    const [authorInfo, setAuthorInfo] = React.useState({});
    const [recipientInfo, setRecipientInfo] = React.useState({});

    function returnUserString(userInfo, defaultEmail) {
        console.log('userInfo2: userInfo = ', userInfo);
        console.log('userInfo2: defaultEmail = ', defaultEmail);
        let userString = defaultEmail;
        if (Object.keys(userInfo).length > 0) {
            if (userInfo.name && userInfo.name !== '') {
                userString = userInfo.name;
            } else if (userInfo.email && userInfo.email !== '') {
                userString = userInfo.email;
            }
        }

        console.log('userInfo2: userString = ', userString);
        return userString;
    }

    const [fuzzis, setFuzzis] = React.useState([]);
    useEffect(() => {
        getFuzzisLocal();
        getUserInfoLocal();
    }, [])

    const getUserInfoLocal = async() => {
        if (jar.author !== undefined && jar.recipient !== undefined) {
            var authorInfo = await getUserByEmail(jar.author);
            var recipientInfo = await getUserByEmail(jar.recipient);
            try {
                setAuthorInfo(authorInfo);
                setRecipientInfo(recipientInfo);
            } catch (err) {
    
            }
        }
    };

    const getFuzzisLocal = async() => {
        var fuzzis = await getFuzzis(jar.jarId);
        try {
          setFuzzis(fuzzis);
        } catch (err) {

        }
    };

    

    const handleOpen = () => {
        console.log('fuzzis: fuzzis = ', fuzzis);
        setCurrentJarFuzzis(fuzzis);
        setActiveJar(jar);
        setIsViewingJar(true);
        if (props.isEditable) {
            setIsEditingJar(true);
        }
    }

    function returnOpenedFuzziCount() {
        var openedFuzzis = [];
        fuzzis.forEach(fuzzi => {
            if (fuzzi.isOpened) {
                openedFuzzis.push(fuzzi);
            }
        });
        return openedFuzzis.length;
    }

    const buttonText = props.isEditable ? 'Edit' : 'Open';
    const subtitleText = props.isEditable ? "Fuzzi's Created" : "Fuzzi's Opened";
    const numerator = props.isEditable ? fuzzis.length : returnOpenedFuzziCount();
    const denominator = props.isEditable ? jar.fuzziMax : fuzzis.length;

    let fuzziAmountText;
    if (props.isEditable) {
        if (fuzzis.length >= jar.fuzziMax - 5) {
            fuzziAmountText = `${numerator}/${denominator}`;
        } else {
            fuzziAmountText = numerator;
        }
    } else {
        fuzziAmountText = `${numerator}/${denominator}`;
    }

    var jarSrc;
    if (props.isEditable) {
        jarSrc = fuzziJar;
    } 
    if (props.role === 'gifter') {
        jarSrc = defaultJar;
    }
    if (props.role === 'recipient') {
        jarSrc = giftedJar;
    }

    const sentByText = props.isEditable ? 'Edited by' : 'Sent by';

    return (
        <div className="fuzzi-card">
            <Paper className="container" elevation={3}>
                <div className="header">
                    <img src={authorInfo.picture ?? defaultProfilePic} alt="" className="author-prof-pic"/>
                    <div className="authors-text">
                        <Typography variant="body2">{sentByText}</Typography>
                        <Typography className="bold" variant="body2">{returnUserString(authorInfo, jar.author)}</Typography>
                        { jar.collaborators.length > 0 ?
                            <div className="authors-text">
                                <Typography variant="body2">and</Typography>
                                <CollaboratorDialog variant="body2" text={`${jar.collaborators.length} other${jar.collaborators.length > 1 ? 's' : ''}`} collaborators={jar.collaborators} />
                            </div> :
                            null
                        }
                    </div>
                </div>
                <Typography variant="h6" className="title">{jar.title}</Typography>
                <div className="content">
                    <div className="jar">
                        <img src={jarSrc} alt=""/>
                    </div>
                    <div className="details">
                        <div className="details-text">
                        <Typography variant="h4" className="fraction">{fuzziAmountText}</Typography>
                            <Typography variant="subtitle2">{subtitleText}</Typography>
                        </div>
                        <Button variant="contained" color="secondary" onClick={handleOpen}>{buttonText}</Button>
                    </div>
                </div>
                <div className="footer">
                    { jar.recipient === '' ?
                        <Typography variant="subtitle2" align="left">No assigned recipient</Typography> :
                        <div className="footer-info">
                            <img src={recipientInfo.picture ?? defaultProfilePic} alt="" className="prof-pic"/>
                            <div className="info-text">
                                <Typography variant="subtitle2">{returnUserString(recipientInfo, jar.recipient)}</Typography>
                            </div>
                        </div>
                    }
                </div>
            </Paper>
        </div>
    );
}




export default connect(null, mapDispatch)(FuzziCard);