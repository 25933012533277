import React from 'react';
import './HomeSection2.css';
import { Typography, Button } from '@material-ui/core';
import WorldJar from '../../WorldJar/WorldJar';

export default function HomeSection2(props) {
    return (
        <div className="home-section-2">
            <div className="background" /> 
            <WorldJar isHomePage/>
        </div>
    );
}