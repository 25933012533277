
import { db, firebase } from '../../../index';
import { setActiveJar, setIsViewingJar } from '../../Dashboard/DashboardSlice';


//notes

//maxFuzzis

//maxCollaborators

//jar date/ fuzzi date


//create jar

//author: string
//collaborators: [string]
//recipient: string
//title: string

export async function createJar(author, collaborators, recipient, title) {
    console.log('createJar: (3) createJar author: ', author, ' collaborators: ', collaborators, ' recipient: ', recipient, ' title: ', title);

    var newDocRef = db.collection('jars').doc();
        newDocRef.set({
                        author,
                        collaborators: collaborators === '' ? collaborators : [],
                        recipient,
                        title,
                        jarId: newDocRef.id,
                        fuzziMax: 50,
                        collabMax: 15,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp()
                })

    const jar = {
        author,
        collaborators,
        recipient,
        title,
        jarId: newDocRef.id
    }

    return jar;

};


//create fuzzi

export function createFuzzi(message, author, media, jarId) {
    
    var newDocRef = db.collection('fuzzis').doc();
        newDocRef.set({
                        message,
                        author,
                        fuzziId: newDocRef.id,
                        media,
                        jarId,
                        isOpened: false,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp()
                })
}


//published boolean


//query for all jars of a single user that they have created pass a param for author/recipient

//make sure you pass either author or recipient to queryField

export async function getJars(userId, queryField) {
    console.log('getJars: (2) firebase - userId = ', userId, ' queryField = ', queryField);
    const query = db.collection('jars').where(queryField, "==", userId).limit(100);
    const fuzziArray = [];

    const snapshot = await query.get();
    snapshot.forEach(function(doc) {
        const data = doc.data();
        fuzziArray.push(data);
    });

    // console.log(fuzziArray);

    console.log('getJars: (2) firebase - fuzziArray = ', fuzziArray);
    return fuzziArray;
};


export async function getJarsByCollaborator(email) {

    const collection = db.collection('jars');

    const query = collection.where('collaborators', 'array-contains', email)

    const jarArray = [];

    const snapshot = await query.get();
    snapshot.forEach(function(doc) {
        const data = doc.data();
        jarArray.push(data);
    });

    return jarArray;

}

//getJar

export async function getJar(jarId) {
    console.log('getJar: (2) getJar - jarId = ', jarId);
    
    let jar = {};

    var docRef = db.collection('jars').doc(jarId);

    const doc = await docRef.get();

    if (doc.exists) {
        console.log("getJar: (2) Document data:", doc.data());
        jar = doc.data();
        return jar;
    } else {
        // doc.data() will be undefined in this case
        console.log("getJar: (2) No such document!");
        return jar;
    }
};

//edit jar title

export async function editJarTitle(jarId, title) {
    db.collection('jars').doc(jarId).set({
        title
    }, { merge: true });
}

//add collaborators

export async function addCollaborators(jarId, email) {
    db.collection('jars').doc(jarId).update({
        collaborators: firebase.firestore.FieldValue.arrayUnion(email)
    });
}

//delete collaborators

export async function deleteCollaborator(jarId, email) {
    db.collection('jars').doc(jarId).update({
        collaborators: firebase.firestore.FieldValue.arrayRemove(email)
    });
}

//add recipient

export async function addJarRecipient(jarId, recipient) {
    db.collection('jars').doc(jarId).set({
        recipient
    }, { merge: true });
}

//delete recipient

export async function deleteJarRecipient(jarId) {
    db.collection('jars').doc(jarId).set({
        recipient: ""
    }, { merge: true });
}

//publish jar

export async function publishJar(jarId) {
    db.collection('jars').doc(jarId).set({
        published: true,
        publishedDate: firebase.firestore.FieldValue.serverTimestamp()
    }, { merge: true });
}

//edit fuzzi

export async function editFuzzi(fuzzi) {
    db.collection('fuzzis').doc(fuzzi.fuzziId).set({
        fuzzi
    }, { merge: true });
}

//delete jar

export async function deleteJar(jarId) {
    db.collection("jars").doc(jarId).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
} 

//delete fuzzi

export async function deleteFuzzi(fuzziId) {
    db.collection("fuzzis").doc(fuzziId).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
} 

//query for all jars that are gifted

//query for all jars that are collaborator



//query fuzzis for jar

export async function getFuzzis(jarId) {
    const query = db.collection('fuzzis').where("jarId", "==", jarId).orderBy("isOpened", "desc").limit(100);
    const fuzziArray = [];

    const snapshot = await query.get();
    snapshot.forEach(function(doc) {
        const data = doc.data();
        fuzziArray.push(data);
    });

    // console.log("getFuzzis: ", fuzziArray);

    return fuzziArray;
};

// send gift email


export function sendGiftEmail(jarId, email, author, name, picture) {
    var newDocRef = db.collection('jars').doc(jarId).collection('giftEmail').doc();
        newDocRef.set({
                    email,
                    author,
                    name,
                    picture,
                    url: `https://warmandfuzzi.com/login/${author}/${jarId}`,
                    status: 'sent'
                })
}


// send collaborator email


export function sendCollaboratorEmail(jarId, email, author, name, picture) {
    var newDocRef = db.collection('jars').doc(jarId).collection('collaboratorEmail').doc();
        newDocRef.set({
                    email,
                    author,
                    name,
                    picture,
                    url: `https://warmandfuzzi.com/login/collaborator/${author}/${jarId}`,
                    status: 'sent'
                })
}

// send contact email


export function sendContactEmail(email, author, name, body) {
    var newDocRef = db.collection('contact').doc();
        newDocRef.set({
                    email,
                    author,
                    name,
                    body,
                    status: 'sent'
                })
}

// set isOpened fuzzi

export async function setOpenedFuzzi(fuzziId, b) {
    db.collection('fuzzis').doc(fuzziId).set({
        isOpened: b,
    }, { merge: true });
}


//update user

//name: ''
//picture: ''
//email: ''
//provider: ''
//createdAt: timestamp

export async function updateUser(userId, user) {
    db.collection('users').doc(userId).set(user, { merge: true });
}


export async function getUserById(userId) {

    let user = {};

    let docRef = db.collection('users').doc(userId);

    const doc = await docRef.get();

    if (doc.exists) {
        console.log("getUser: (2) Document data:", doc.data());
        user = doc.data();
        return user;
    } else {
        // doc.data() will be undefined in this case
        // console.log("getUser: (2) No such document!");
        return user;
    }

}


export async function getUserByEmail(email) {
    if (!email) { return {}; }
    console.log('userInfo: (getUserByEmail) email = ', email);

    let user = {};

    const query = db.collection('users').where('email', "==", email);

    const snapshot = await query.get();

    snapshot.forEach(function(doc) {
        const data = doc.data();
        user = data;
    })

    // console.log(user);

    console.log('userInfo: (getUserByEmail) user = ', user);
    return user;

}




