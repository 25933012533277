
import React from 'react';
import FlareComponent from 'flare-react';

export default class FuzziOpenController extends FlareComponent.Controller
{
	constructor(handleParticlesOn, handleFuzziGrow)
	{
		super(handleParticlesOn, handleFuzziGrow);
		this._Spawn = null;
        this._Timer = 0;
        this._ParticleTriggerTime = 1.992;
        this._FuzziTriggerTime = 1.992;
        this._HandleParticlesOn = handleParticlesOn;
        this._HandleFuzziGrow = handleFuzziGrow;
	}

	initialize(artboard)
	{
		this._Spawn = artboard.getAnimation("Spawn");
	}

	advance(artboard, elapsed)
	{
		// advance the walk time
		this._Timer += elapsed;
		const { _Spawn: spawn, _Timer: timer, _ParticleTriggerTime: particleTriggerTime, _HandleParticlesOn: handleParticlesOn, _HandleFuzziGrow: handleFuzziGrow, _FuzziTriggerTime: fuzziTriggerTime } = this;

        spawn.apply(timer % spawn.duration, artboard, 1.0);
        
        console.log('timer: timer = ', timer);
        console.log('timer: elapsed = ', elapsed);
        if (timer >= particleTriggerTime) {
            handleParticlesOn();
		}
		
        if (timer >= fuzziTriggerTime) {
            handleFuzziGrow();
		}
		
        if (timer >= fuzziTriggerTime && timer >= particleTriggerTime) {
			return false;
		}

		// keep rendering
		return true;
    }
    
}