import React, { useState } from 'react';
import { Button, TextField, DialogContent, Dialog, DialogTitle, DialogContentText, DialogActions, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { editJarTitle } from '../../../shared/firebase/firebase';
import { getJarAsync } from '../../DashboardSlice';
import { useDispatch, connect, useSelector } from 'react-redux';

const mapDispatch = { };

function EditJarTitleDialog(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [value, setValue] = useState('');
  
    const jar = props.jar;


    const handleClickOpen = () => {
        setOpen(true);
        setValue(jar.title);
        if (jar.title.length > 0) {
            setDisabled(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);

        let charCount = event.target.value.length;

        if (charCount > 25) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    };

    const handleSubmit = () => {
        editJarTitle(jar.jarId, value);
        dispatch(getJarAsync(jar.jarId));
        handleClose();
        setValue('');
    }

    const title = 'Edit Jar Title';
    const description = "Edit the title for this jar";
    
    return (
        <div className="dialog">
            <Button onClick={handleClickOpen}>
                <Typography variant="h3">{jar.title}</Typography>
            </Button>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="add-user-dialog">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent className="dialog-content">
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Title"
                        type="text" 
                        fullWidth
                        value={value}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} disabled={disabled} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(null, mapDispatch)(EditJarTitleDialog);