import React, { useEffect } from 'react';
import { Button, TextField, DialogContent, Dialog, DialogTitle, DialogContentText, DialogActions, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { addJarRecipient } from '../../../shared/firebase/firebase';
import { getJarAsync } from '../../DashboardSlice';
import { useDispatch, connect, useSelector } from 'react-redux';
import './JarDialogs.css';

const mapDispatch = { };

function EditJarRecipientDialog(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
  
    const jar = props.jar;


    const handleClickOpen = () => {
      setOpen(true);
      setValue(jar.recipient);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        if (validateEmail(value)) {
            addJarRecipient(jar.jarId, value);
            dispatch(getJarAsync(jar.jarId));
            handleClose();
            setValue('');
        } else {
            console.log('error: email is invalid');
        }
    }

    const buttonText = 'Add Recipient +';
    const title = 'Add a Recipient';
    const description = "Enter a user's email to add them as the recipient for this jar";

    console.log('editJarRecpient: jar = ', jar);
    console.log('editJarRecpient: recipient = ', jar.recipient);

    return (
        <div className="dialog">
            <Button onClick={handleClickOpen} className="manager-dialog-button">
                { jar.recipient !== '' ? 
                    <div>
                        <Typography variant="body2">{jar.recipient}</Typography>
                        <div />
                        <Typography variant="body2" align="left" className="edit-button-label">Recipient</Typography>
                    </div> :
                    <Typography variant="body2">{buttonText}</Typography>
                }
            </Button>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="add-user-dialog">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent className="dialog-content">
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={value}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default connect(null, mapDispatch)(EditJarRecipientDialog);