import React, { useEffect } from 'react';
import { getFuzzis } from '../shared/firebase/firebase';
import { selectIsFuzziAnimating, setIsFuzziAnimating } from '../Dashboard/DashboardSlice';
import FuzziAnimation from '../Dashboard/FuzziAnimation/FuzziAnimation';
import { connect, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import './WorldJar.css';

const mapDispatch = { setIsFuzziAnimating };

function ReceiveFuzziWorldJar(props) {

    const { setIsFuzziAnimating } = props;

    const [openedFuzzi, setOpenedFuzzi] = React.useState();
    const isFuzziAnimating = useSelector(selectIsFuzziAnimating);

    const handleOpenFuzzi = () => {
        const fuzzi = selectRandomWorldJarFuzzi();
        console.log('worldJar: (handleOpenFuzzi) fuzzi = ', fuzzi)
        setOpenedFuzzi(fuzzi);
        setIsFuzziAnimating(true);
    }

    const [worldJarFuzzis, setWorldJarFuzzis] = React.useState([]);

    useEffect(() => {
        getFuzzisAsync();
    }, [])

    const getFuzzisAsync = async() => {
        // hard coded worldJar jarId
        var fuzzis = await getFuzzis('a12GNZY06q7R37Vy7XOu');
        try {
            setWorldJarFuzzis(fuzzis);
        } catch (err) {

        }
    };

    function selectRandomWorldJarFuzzi() {
        const rand = Math.floor(Math.random() * Math.floor(worldJarFuzzis.length));
        const fuzzi = worldJarFuzzis[rand]; 
        return fuzzi;
    }

    return (
        <div>
            { isFuzziAnimating ?
                <FuzziAnimation fuzzi={openedFuzzi} id={props.id} href={props.href} buttonText={props.confirmButtonText} isGetStarted={props.isGetStarted}/> : null
            }
            <Button className={props.isGetStarted ? "get-started" : ""} variant="contained" color="secondary" onClick={handleOpenFuzzi}>{props.buttonText}</Button>
        </div>
    );
}

export default connect(null, mapDispatch)(ReceiveFuzziWorldJar);