import React from 'react';
import './FuzziAnimation.css';
import fuzziOpen from '../../../assets/Animations/fuzziOpen.flr';
import StarsParticle from '../../../assets/Particles/StarsParticle.json';
import FlareComponent from 'flare-react';
import Particles from 'react-particles-js';
import FuzziOpenController from './FuzziOpenController';
import Fuzzi from '../Fuzzi/Fuzzi';
import { Zoom, Button, Fade, Typography, ClickAwayListener } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setIsFuzziAnimating, getFuzzisAsync, selectGiftedFuzzi } from '../DashboardSlice'
import useWindowSize from '../../../useWindowSize';

const mapDispatch = { setIsFuzziAnimating };

function FuzziAnimation(props) {
    const dispatch = useDispatch();

    const { setIsFuzziAnimating } = props;
    const giftedFuzzi = useSelector(selectGiftedFuzzi);

    let fuzzi;
    if (props.fuzzi) {
        if (Object.keys(props.fuzzi).length > 0) {
            fuzzi = props.fuzzi;
        } else {
            fuzzi = giftedFuzzi;
        }
    }
    console.log('jarTest: (FuzziAnimation) props.fuzzi = ', props.fuzzi);
    console.log('jarTest: (FuzziAnimation) giftedFuzzi = ', giftedFuzzi);
    console.log('jarTest: (FuzziAnimation) fuzzi = ', fuzzi);

    const [particlesOn, setParticlesOn] = React.useState(false);
    const [fuzziGrow, setFuzziGrow] = React.useState(false);

    function handleParticlesOn() {
        setParticlesOn(true);
    }

    function handleFuzziGrow() {
        setFuzziGrow(true);
    }

    const handleConfirm = () => {
        setIsFuzziAnimating(false);
        if (props.getFuzzisOnConfirm) {
            dispatch(getFuzzisAsync(fuzzi.jarId))
        }
    }

    const handleClickAway = () => {
        if (particlesOn) {
            handleConfirm();
        }
    }

    const fuzziOpenController = new FuzziOpenController(handleParticlesOn, handleFuzziGrow);

    console.log('worldJar: (FuzziAnimation) id = ', props.id, ' fuzzi = ', fuzzi);

    const windowSize = useWindowSize();

    return (
        <div className="fuzzi-animation">
            {
                !particlesOn ?
                    <FlareComponent controller={fuzziOpenController} file={fuzziOpen} transparent={true} width={windowSize.width} height={windowSize.height} className="animation" /> : null
            }
            <Zoom in={particlesOn} timeout={{enter: 1000}}>
                <div className="particle-grow">
                    <Particles
                        className="particles" 
                        params={StarsParticle}
                    />    
                </div>
            </Zoom>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className="content">
                    <Zoom in={fuzziGrow} timeout={{enter: 1000}}>
                        <div className="fuzzi-grow">
                                <Fuzzi fuzzi={fuzzi} isAnimating className="animated-fuzzi"/>
                        </div>
                    </Zoom>
                    <Fade in={fuzziGrow} timeout={{enter: 1000}} style={{ transitionDelay: fuzziGrow ? '2000ms' : '0ms' }}>
                        <div>
                            { props.isGetStarted ?
                                <Typography variant="h3" color="primary" className="call-to-action">Send a virtual jar of Fuzzi's to <br/> someone you care about</Typography> : null
                            }
                            <Button variant="contained" color="secondary" onClick={handleConfirm} href={props.href ? props.href : null} className="confirm-fuzzi">
                                { props.buttonText ? props.buttonText : 'CONFIRM'}
                            </Button>
                        </div>
                    </Fade>
                </div>
            </ClickAwayListener>
        </div>
    );

}

export default connect(null, mapDispatch)(FuzziAnimation); 