import React from "react";
import './ErrorMessage.css';
import { Typography } from '@material-ui/core';


export default function ErrorMessage({error, firebaseMsg}) {
    if (error) {
      switch (error.type) {
        case "required":
          return (
            <div className="error-msg">
              <Typography variant="body1" color="error">This is required</Typography>
            </div>
          );
        case "minLength":
          return (
            <div className="error-msg">
              <Typography variant="body1" color="error">You need at least 6 characters</Typography>
            </div>
          );
        case "pattern":
          return (
            <div className="error-msg">
              <Typography variant="body1" color="error">Enter a valid email address</Typography>
            </div>
          );
        case "validate":
          return (
            <div className="error-msg">
              <Typography variant="body1" color="error">Both password fields must match</Typography>
            </div>
          );
          case "firebase":
            return (
              <div className="error-msg">
                <Typography variant="body1" color="error">{error.message}</Typography>
              </div>
            );
          case "passwordReset":
            return (
              <div className="password-sent">
                <Typography variant="body1" color="error">{firebaseMsg}</Typography>
              </div>
            );
        default:
          return null;
        }
    }
  
  return null;
}