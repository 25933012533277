import React, { useEffect } from 'react';
import { Button, TextField, DialogContent, Dialog, DialogTitle, DialogContentText, DialogActions, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useUser } from 'reactfire';
import DeleteIcon from '@material-ui/icons/Delete';
import { addCollaborators, deleteCollaborator, sendCollaboratorEmail } from '../../../shared/firebase/firebase';
import { getJarAsync, selectIsMobile, selectUserInfo, getUserInfoAsync } from '../../DashboardSlice';
import { useDispatch, connect, useSelector } from 'react-redux';
import './JarDialogs.css';

const mapDispatch = { };

function EditJarCollaboratorsDialog(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');

    const user = useUser() ?? { uid: 'null' };
  
    const jar = props.jar;

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        dispatch(getUserInfoAsync(user.uid));
    }, [dispatch, user.uid])


    const handleClickOpen = () => {
      setOpen(true);
      setValue('');
    };
    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        if (validateEmail(value)) {
            addCollaborators(jar.jarId, value);
            dispatch(getJarAsync(jar.jarId));
            sendCollaboratorEmail(jar.jarId, value, user.uid, userInfo.name, userInfo.picture);
            setValue('');

        } else {
            console.log('sorry that is not a valid email')
        }
    }

    const handleDeleteCollaborator = (email) => {
        deleteCollaborator(jar.jarId, email);
        dispatch(getJarAsync(jar.jarId));
    }


    const buttonText = 'Add Collaborators +';
    const title = 'Add Collaborators';
    const description = "Enter a user's email to add them as a collaborator to this jar";

    console.log('jarDialog: jar = ', jar);
    console.log('jarDialog: collaborators = ', jar.collaborators);

    const isMobile = useSelector(selectIsMobile);

    const multipleCollaboratorsText = isMobile ? `(${jar.collaborators.length})` : `${jar.collaborators[0]} and ${jar.collaborators.length - 1} others...`;

    return (
        <div className="dialog">
            <Button onClick={handleClickOpen} className="manager-dialog-button">
                { jar.collaborators.length > 0 ?
                    <div>
                        { jar.collaborators.length > 1 ?
                            <Typography variant="body2" align="left">{multipleCollaboratorsText}</Typography> :
                            <Typography variant="body2">{jar.collaborators[0]}</Typography>
                        }
                        <div />
                        <Typography align="left" variant="body2" className="edit-button-label">Collaborators</Typography>
                    </div> :
                    <Typography variant="body2">{buttonText}</Typography>
                }
            </Button>
            <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="add-user-dialog">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent className="dialog-content">
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                    <List >
                        { jar.collaborators.map((email) => {
                            return (
                                <ListItem>
                                    <ListItemText
                                        primary={email}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={handleDeleteCollaborator.bind(this, email)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                        
                        </List>
                    
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={value}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default connect(null, mapDispatch)(EditJarCollaboratorsDialog);