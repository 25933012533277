import React, { Suspense, useEffect } from 'react';
import './Home.css';
import HomeSection1 from './HomeSection1/HomeSection1';
import HomeSection2 from './HomeSection2/HomeSection2';
import HomeSection3 from './HomeSection3/HomeSection3';
import HomeSection4 from './HomeSection4/HomeSection4';
import FinalGetStarted from './FinalGetStarted/FinalGetStarted';
import HomeFooter from './HomeFooter/HomeFooter';
import Splash from './Splash/Splash';
import useWindowSize from '../../useWindowSize';
import '../../StyleSheet/Breakpoints.css';
import '../../StyleSheet/GlobalDynamicSizing.css';
import { connect, useSelector } from 'react-redux';
import { setActiveBreakpoint, selectActiveBreakpoint } from './homeSlice';


const mapDispatch = { setActiveBreakpoint }



function HomeAuthComponent(props) {

  const { setActiveBreakpoint } = props;

  return (
      <Suspense fallback={''}>
          <Home setActiveBreakpoint={setActiveBreakpoint}/>
      </Suspense>
  );
}

function Home(props) {

  const activeBreakpoint = useSelector(selectActiveBreakpoint)
  const windowSize = useWindowSize();
  console.log('windowSize: width = ', windowSize.width);

  if (windowSize.width < 600 && activeBreakpoint !== 1) {
    props.setActiveBreakpoint(1);
  } else if (windowSize.width >= 600 && windowSize.width < 900 && activeBreakpoint !== 2) {
    props.setActiveBreakpoint(2);
  } else if (windowSize.width >= 900 && windowSize.width < 1400 && activeBreakpoint !== 3) {
    props.setActiveBreakpoint(3);
  } else if (windowSize.width >= 1400 && activeBreakpoint !== 4) {
    props.setActiveBreakpoint(4);
  }

    return (
      <div className="home">
        <div className="gradient-angle" />
        <div className="content">
          <Splash />
          <HomeSection1 />
          <HomeSection2 />
          <HomeSection3 />
          <HomeSection4 />
          <FinalGetStarted />
          <HomeFooter />
        </div>
      </div>
    );
  }

export default connect(null, mapDispatch)(HomeAuthComponent);
