import React, { Suspense, useState, useEffect } from 'react';
import './Auth.css';
import { AuthCheck, useAuth } from 'reactfire';
// import firebase from 'firebase/app';
import { useForm } from 'react-hook-form';
import { auth, db, firebase } from '../../index';
import ErrorMessage from './ErrorMessage';
// import { Redirect } from 'react-router-dom';
import { useDispatch, connect, useSelector } from 'react-redux';
import { flipBool } from './authSlice';
import {useHistory, Redirect, useLocation } from 'react-router-dom';
import { getUserById, getJar, setOpenedFuzzi } from '../shared/firebase/firebase';
import Nav from '../Nav/Nav';
import authBackground from '../../assets/authBackground.png';
import wearMask from '../../assets/wearMask.png';
import { defaultPics } from '../shared/sharedObjects';

import { Typography, Button, SvgIcon } from '@material-ui/core';
import '../../StyleSheet/GlobalStyling.css';
import googleIcon from '../../assets/googleIcon.png';
import defaultProfilePic from '../../assets/defaultProfilePic.png';
import { setActiveJar, setIsFuzziAnimating, setIsViewingJar, getFuzzisAsync, selectCurrentJarFuzzis, setGiftedFuzzi, setIsMobile, selectIsMobile } from '../Dashboard/DashboardSlice';
import useWindowSize from '../../useWindowSize';

const mapDispatch = { setActiveJar, setIsFuzziAnimating, setIsViewingJar, setGiftedFuzzi, setIsMobile };

export default connect (null, mapDispatch)(AuthComponent);

function AuthComponent(props) {
  const location = useLocation();

  const { setActiveJar, setIsFuzziAnimating, setIsViewingJar, setGiftedFuzzi, setIsMobile } = props;

  const reducers = { setActiveJar, setIsFuzziAnimating, setIsViewingJar, setGiftedFuzzi };

  const [giverInfo, setGiverInfo] = useState({});
  const [giftedJar, setGiftedJar] = useState({});
  const [giftBool, setGiftBool] = useState(false);
  const [collabBool, setCollabBool] = useState(false);

  useEffect(() => {
    async function fetchEmailInfo() {
      const emailCheckInfo = await emailCheck(location.pathname);
      console.log(emailCheckInfo)
      if (typeof emailCheckInfo.giverInfo != "undefined") {
        if (typeof emailCheckInfo.type != "undefined") {
          setGiverInfo(emailCheckInfo.giverInfo ?? {});
          setGiftedJar(emailCheckInfo.giftedJar ?? {});
          setCollabBool(true);
          setGiftBool(false);
        } else {
          setGiverInfo(emailCheckInfo.giverInfo ?? {});
          setGiftedJar(emailCheckInfo.giftedJar ?? {});
          setGiftBool(true);
          setCollabBool(false);
        }
      } else {
        setGiftBool(false);
        setCollabBool(false);
      }
    }
    fetchEmailInfo();

  }, [location.pathname])

  const windowSize = useWindowSize();
  const isMobile = useSelector(selectIsMobile);
  
  /* MOBILE MEDIA */
  if (windowSize.width <= 1200) {
    if (!isMobile) {
      setIsMobile(true);
    }
  } else {
    if (isMobile) {
      setIsMobile(false);
    }
  }
    return (
      <Suspense fallback={''}>
            <AuthProfile giverInfo={giverInfo} giftedJar={giftedJar} giftBool={giftBool} collabBool={collabBool} reducers={reducers}/>
        </Suspense> 
    );
  }

  function AuthProfile(props) {
    // const newUser = useSelector(selectNewUser);
    // console.log(`newUser::: ${newUser}`);

    // console.log(`giverInfo::: ${JSON.stringify(props.giverInfo)}`);

    return (
      <div className="authForm">
        <Nav /> 
        <div className="body">
          <AuthCheck fallback={props.giftBool ? < GiftLoginComponent giverInfo={props.giverInfo} giftedJar={props.giftedJar} reducers={props.reducers}/> : props.collabBool ? < CollaboratorLoginComponent giverInfo={props.giverInfo} giftedJar={props.giftedJar} reducers={props.reducers}/> : < LoginComponent/> }>
              <Redirect to="/dashboard"/>
          </AuthCheck>
        </div>
      </div>

    );
  }


  function GiftLoginComponent(props) {
    const dispatch = useDispatch();
    const [isUser, setIsUser] = useState(false);
    const toggleIsUser = () => setIsUser(!isUser);
    const [forgotPasswordBool, setForgotPassword] = useState(false);
    const toggleForgotPassword = () => setForgotPassword(!forgotPasswordBool);

    const headerText = forgotPasswordBool ? 'Forgot Password' : isUser ? 'Sign in' : 'Create new account';
    const linkText = forgotPasswordBool ? 'Back' : isUser ? "Don't have an account?" : 'Returning user?';

    const name = props.giverInfo.name ?? '';

    // console.log(`giverInfo::::123 ${name}`)

    // const name = giverInfo['name'] ?? '';

    // const giftedJar = props.emailInfo.giftedJar;

    const { setActiveJar, setIsFuzziAnimating, setIsViewingJar, setGiftedFuzzi } = props.reducers;
    const currentJarFuzzis = useSelector(selectCurrentJarFuzzis);

    const giftedJar = props.giftedJar;

    useEffect(() => {
      console.log('jarTest: (1) giftedJar = ', giftedJar);
      setActiveJar(giftedJar);
      console.log('jarTest: (2) jarId = ', giftedJar.jarId);
      dispatch(getFuzzisAsync(giftedJar.jarId));
      // getJarTest();
      setIsFuzziAnimating(true);
      setIsViewingJar(true);
    }, [])

  //   const getJarTest = async() => {
  //     var jar = await getJar('ilxD2doaju4Pq1jTcS3r');
  //     try {
  //       console.log('jarTest: (getJar) jar = ', jar);
  //       setActiveJar(jar);
  //       dispatch(getFuzzisAsync('ilxD2doaju4Pq1jTcS3r'));
  //     } catch (err) {

  //     }
  // };

  useEffect(() => {
    console.log('jarTest: (5) (useEffect) currentJarFuzzis = ', currentJarFuzzis);
    const fuzzi = randomFuzzi();
    console.log('jarTest: (6) (useEffect) randomFuzzi = ', fuzzi);
    setGiftedFuzzi(fuzzi);
    if (fuzzi) {
      setOpenedFuzzi(fuzzi.fuzziId, true);
    }
  }, [currentJarFuzzis])

  function randomFuzzi() {
      var unopenedFuzzis = [];
      currentJarFuzzis.forEach(fuzzi => {
          if (!fuzzi.isOpened) {
              unopenedFuzzis.push(fuzzi);
          }
      });
      if (unopenedFuzzis.length === 0) { return null; }

      const rand = Math.floor(Math.random() * Math.floor(unopenedFuzzis.length));
      const fuzzi = unopenedFuzzis[rand];

      return fuzzi;
  }

  console.log('jarTest: currentJarFuzzis = ', currentJarFuzzis);


    return (
      <div className="auth-content">
        <div className="invite-image-div">
          <div className="invite-header">
            <img src={props.giverInfo.picture ?? defaultProfilePic} alt="" className="prof-pic"/>
            <Typography variant="h4" className="header" >{name !== '' ? name : 'Someone'} has sent you a Warm & Fuzzi Jar!</Typography>
          </div>
          <img src={wearMask} alt="" className="background-image"/>
        </div>
        <div className="login-component">
          <div className="container">
          <Typography variant="h4" className="header">Congratulations!</Typography>
          <Typography variant="subtitle2">Someone you know cares about you. Sign up to receive your Warm & Fuzzi Jar!</Typography>
            <br></br>
            <Typography variant="h4" className="header" align="left">{headerText}</Typography>
            <Button className="link" onClick={forgotPasswordBool ? toggleForgotPassword : toggleIsUser}>{linkText}</Button>
            <div className="divider"/>
            { forgotPasswordBool ?
              <Typography>Enter your email to receive instructions on resetting your password</Typography> : null
            }
            <AuthCard isUser={isUser} forgotPasswordBool={forgotPasswordBool}/>
            { !forgotPasswordBool ?
              <div>
                <Typography variant="subtitle2" className="or">Or</Typography>
                <ExternalProviderComponent/> 
              </div> :
              null
            }
            { !forgotPasswordBool && isUser ?
              <Button className="link" onClick={toggleForgotPassword}>Forgot Password?</Button> : 
              null
            }
          </div>
        </div>
      </div>
    );
  }

  //Collaborator Login Component

  function CollaboratorLoginComponent(props) {
    const dispatch = useDispatch();
    const [isUser, setIsUser] = useState(false);
    const toggleIsUser = () => setIsUser(!isUser);
    const [forgotPasswordBool, setForgotPassword] = useState(false);
    const toggleForgotPassword = () => setForgotPassword(!forgotPasswordBool);

    const headerText = forgotPasswordBool ? 'Forgot Password' : isUser ? 'Sign in' : 'Create new account';
    const linkText = forgotPasswordBool ? 'Back' : isUser ? "Don't have an account?" : 'Returning user?';

    const name = props.giverInfo.name ?? '';

    const { setActiveJar, setIsFuzziAnimating, setIsViewingJar, setGiftedFuzzi } = props.reducers;
    const currentJarFuzzis = useSelector(selectCurrentJarFuzzis);

    const giftedJar = props.giftedJar;

    useEffect(() => {
      console.log('jarTest: (1) giftedJar = ', giftedJar);
      setActiveJar(giftedJar);
      console.log('jarTest: (2) jarId = ', giftedJar.jarId);
      dispatch(getFuzzisAsync(giftedJar.jarId));
      // getJarTest();
      setIsFuzziAnimating(true);
      setIsViewingJar(true);
    }, [dispatch, giftedJar, setActiveJar, setIsFuzziAnimating, setIsViewingJar])

  useEffect(() => {
    console.log('jarTest: (5) (useEffect) currentJarFuzzis = ', currentJarFuzzis);
    const fuzzi = randomFuzzi();
    console.log('jarTest: (6) (useEffect) randomFuzzi = ', fuzzi);
    setGiftedFuzzi(fuzzi);
    if (fuzzi) {
      setOpenedFuzzi(fuzzi.fuzziId, true);
    }
  }, [currentJarFuzzis])

  function randomFuzzi() {
      var unopenedFuzzis = [];
      currentJarFuzzis.forEach(fuzzi => {
          if (!fuzzi.isOpened) {
              unopenedFuzzis.push(fuzzi);
          }
      });
      if (unopenedFuzzis.length === 0) { return null; }

      const rand = Math.floor(Math.random() * Math.floor(unopenedFuzzis.length));
      const fuzzi = unopenedFuzzis[rand];

      return fuzzi;
  }

  console.log('jarTest: currentJarFuzzis = ', currentJarFuzzis);


    return (
      <div className="auth-content">
        <div className="invite-image-div">
          <div className="invite-header">
            <img src={props.giverInfo.picture ?? defaultProfilePic} alt="" className="image"/>
            <Typography variant="h4" className="header" >{name !== '' ? name : 'Someone'} has invited you to collaborate on a Warm & Fuzzi Jar!</Typography>
          </div>
          <img src={wearMask} alt="" className="background-image"/>
        </div>
        <div className="login-component">
          <div className="container">
          <Typography variant="h4" className="header">Start creating!</Typography>
          <Typography variant="subtitle2">Help make someone feel special. Sign up to work together on a Warm & Fuzzi Jar!</Typography>
            <br></br>
            <Typography variant="h4" className="header" align="left">{headerText}</Typography>
            <Button className="link" onClick={forgotPasswordBool ? toggleForgotPassword : toggleIsUser}>{linkText}</Button>
            <div className="divider"/>
            { forgotPasswordBool ?
              <Typography>Enter your email to receive instructions on resetting your password</Typography> : null
            }
            <AuthCard isUser={isUser} forgotPasswordBool={forgotPasswordBool}/>
            { !forgotPasswordBool ?
              <div>
                <Typography variant="subtitle2" className="or">Or</Typography>
                <ExternalProviderComponent/> 
              </div> :
              null
            }
            { !forgotPasswordBool && isUser ?
              <Button className="link" onClick={toggleForgotPassword}>Forgot Password?</Button> : 
              null
            }
          </div>
        </div>
      </div>
    );
  }

  //login component

  export function LoginComponent() {

    const [isUser, setIsUser] = useState(true);
    const toggleIsUser = () => setIsUser(!isUser);
    const [forgotPasswordBool, setForgotPassword] = useState(false);
    const toggleForgotPassword = () => setForgotPassword(!forgotPasswordBool);

    const headerText = forgotPasswordBool ? 'Forgot Password' : isUser ? 'Sign in' : 'Create new account';
    const linkText = forgotPasswordBool ? 'Back' : isUser ? "Don't have an account?" : 'Returning user?';

    const isMobile = useSelector(selectIsMobile);

    return (
      <div className="auth-content">
        { !isMobile ?
          <img src={authBackground} alt="" className="background-image"/> : null
        }
        <div className="login-component">
          <div className="container">
            <Typography variant="h4" className="header" align="left">{headerText}</Typography>
            <Button className="link" onClick={forgotPasswordBool ? toggleForgotPassword : toggleIsUser}>{linkText}</Button>
            <div className="divider"/>
            { forgotPasswordBool ?
              <Typography>Enter your email to receive instructions on resetting your password</Typography> : null
            }
            <AuthCard isUser={isUser} forgotPasswordBool={forgotPasswordBool}/>
            { !forgotPasswordBool ?
              <div>
                <Typography variant="subtitle2" className="or">Or</Typography>
                <ExternalProviderComponent/> 
              </div> :
              null
            }
            { !forgotPasswordBool && isUser ?
              <Button className="link" onClick={toggleForgotPassword}>Forgot Password?</Button> : 
              null
            }
          </div>
        </div>
      </div>
    );
  }

  //host external providers here

  function ExternalProviderComponent() {
    return (
      <div className="provider-div">
        <SignInWithGoogle/>
        <br/>
      </div>

    );
  }

  //google sign in

  function GoogleIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d={googleIcon}/>
      </SvgIcon>
    );
  }

  export function SignInWithGoogle(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const signIn = async () => {
      const credential = await auth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      );
      const { uid, email, photoURL, displayName } = credential.user;
      const provider = "Google";
      credential.additionalUserInfo?.isNewUser 
        ? db.collection('users').doc(uid).set({ email, provider, createdAt: firebase.firestore.FieldValue.serverTimestamp(), picture: photoURL ?? '', name: displayName ?? '' }, { merge: true }).then(() => dispatch(flipBool()))
        : console.log("returning user");
      history.push('/dashboard');
    };
  
    return (
      <Button className="google-button" variant="contained" onClick={signIn} >
        <img src={googleIcon} alt="" className="google-icon"/>
        Sign In With Google
      </Button>
    );
  }

  //email password auth card
  //TODO:: delete account

  function AuthCard(props) {
    const isUser = props.isUser;
    const forgotPasswordBool = props.forgotPasswordBool;
    return (
      <div className="AuthCard">
        { forgotPasswordBool 
          ? 
          <>
            {/* <Button variant="outlined" color="primary" className="isUser" onClick={ toggleForgotPassword }>Back</Button> */}
            <ForgotPasswordForm />
          </> 
          : 
          <>
            {/* <button type="isUser" onClick={ toggleIsUser }>{ isUser ? "New User?" : "Returning User?" }</button> */}
            { isUser ? <SignInForm /> : <SignUpForm /> } 
            {/* { isUser ? <Button className="forgot-password" onClick={ toggleForgotPassword }>Forgot Password?</Button> : <></> } */}
          </>
        }

      </div>
    );
  }

  function SignInForm() {
    const {register, handleSubmit, setError, errors } = useForm();
    const auth = useAuth();
    const history = useHistory();

    const onSubmit = (data) => {
      auth.signInWithEmailAndPassword(data.email, data.password).then(() => {
        history.push('/dashboard');

      }).catch( error => {
        const errorMsg = (error.message).toString();
        // console.log(errorMsg);
        setError("password", "firebase", errorMsg);
      });
    }
    return (
      <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
        <label>
          <Typography>Email</Typography>
        </label>
        <input
          type="text"
          name="email"
          ref={register({
            required: true,
            // eslint-disable-next-line
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        <ErrorMessage error={errors.email} />
        <label>
          <Typography>Password</Typography>
        </label>
        <input type="password" name="password" ref={register({ required: true, minLength: 6 })}/>
        <ErrorMessage error={errors.password} />
        <Button variant="contained" color="secondary" className="submit" type="submit">Submit</Button>
      </form>
    );
  }

  function SignUpForm() {
    const dispatch = useDispatch();
    const {register, handleSubmit, setError, clearErrors, errors, formState: { isSubmitting }} = useForm();
    const auth = useAuth();
    const history = useHistory();

    const [picture, setPicture] = React.useState('');


    useEffect(() => {
      const picture = getRandomProfilePicture();
      setPicture(picture);
    }, [])

    const onSubmit = async (data) => {
      if (data.passwordConfirm !== data.password) {
        setError("passwordConfirm", "validate");
      } else {
        clearErrors("passwordConfirm");
        await auth.createUserWithEmailAndPassword(data.email, data.password).then((credential) => {
          const { uid, email } = credential.user;
          const provider = "Email"
          const name = data.name;
          db.collection('users').doc(uid).set({ email, provider, createdAt: firebase.firestore.FieldValue.serverTimestamp(), picture, name }, { merge: true }).then(() => dispatch(flipBool()));
          console.log('sending email confirmation');
          credential.user.sendEmailVerification({
            url: 'http://localhost:3000'
          }).then(() => {
            console.log('email sent');
            history.push('/dashboard');
          })
        }).catch( error => {
          const errorMsg = (error.message).toString();
          setError("passwordConfirm", "firebase", errorMsg);
        })
      }
    }
    return (
      <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
        <label>
          <Typography>Email</Typography>
        </label>
        <input
          type="text"
          name="email"
          ref={register({
            required: true,
            // eslint-disable-next-line
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        <label>
          <Typography>Display Name</Typography>
        </label>
        <input
          type="text"
          name="name"
          ref={register({
            required: false,
            // eslint-disable-next-line
            // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        <ErrorMessage error={errors.email} />
        <label>
          <Typography>Password</Typography>
        </label>
        <input type="password" name="password" ref={register({ required: true, minLength: 6 })}/>
        <ErrorMessage error={errors.password} />
        <label>
          <Typography>Confirm Password</Typography>
        </label>
        <input
        type="password"  
        name="passwordConfirm"
        ref={register({ required: true, minLength: 6 })}
      />
        <ErrorMessage error={errors.passwordConfirm} />
        <Button variant="contained" color="secondary" className="submit" disabled={isSubmitting} type="submit" >Submit</Button>
        
      </form>
    );
  }

  //forgot password

  function ForgotPasswordForm() {
    const {register, handleSubmit, setError, errors } = useForm();

    const [emailSent, setEmailSent] = useState(false);
    const toggleEmailSent = () => setEmailSent(!emailSent);

    const onSubmit = (data) => {
      auth.sendPasswordResetEmail(data.email)
      const emailSent = "Check your inbox to reset your password";
      console.log(emailSent);
      setError("email", "passwordReset", emailSent);
      toggleEmailSent(!emailSent);
    }
    return (
      <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
        <label>
          <Typography>Email</Typography>
        </label>
        <input
          type="text"
          name="email"
          ref={register({
            required: true,
            // eslint-disable-next-line
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        <ErrorMessage error={errors.email}/>
        { emailSent ? <p style = {{ color: 'green' }}>{ '✔️ Reset password email sent ' }</p> : <></> }
        <Button variant="contained" color="secondary" className="submit" >Submit</Button>
      </form>
    );
  }

  //sign out
  
  export function SignOut(props) {
    if (props.user) {
      console.log(`emailVerified: ${props.user.emailVerified}`);
    }
    return props.user && (
      <div className="authCard">
        <h2>User {props.user.uid}</h2>
        <button type="isUser" href="login" onClick={() => auth.signOut()}>
          Sign Out
        </button>
      </div>
    );
  }


  function getRandomProfilePicture() {

    const rand = Math.floor(Math.random() * 16);

    const url = defaultPics[rand-1];

    return url;

  }

  async function emailCheck(pathname) {

    const splitPaths = pathname.split( '/' );

    if (splitPaths.length === 4) {
      const userId = splitPaths[2];
      const jarId = splitPaths[3]; 

      // console.log(userId, jarId);

      const giverInfo = await getUserById(userId) ?? {};

      const giftedJar = await getJar(jarId) ?? {};

      return {giverInfo, giftedJar};
    } else if (splitPaths.length === 5) {
      const userId = splitPaths[2];
      const jarId = splitPaths[3];
      const type = splitPaths[4]; 

      // console.log(userId, jarId);

      const giverInfo = await getUserById(userId) ?? {};

      const giftedJar = await getJar(jarId) ?? {};


      return {giverInfo, giftedJar, type};
    } else {
      return {};
    }

  }

