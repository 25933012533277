import React, { useEffect } from 'react';
import { useUser } from 'reactfire';

import { 
  Typography, Button
   } from '@material-ui/core';

import { AddToJar } from '../shared/AddToJar/AddToJar';
import jarHome from '../../assets/jarHome.png';
import './WorldJar.css';

import { useSelector, connect } from 'react-redux';
import { selectActiveBreakpoint } from '../Home/homeSlice';

// import { FirebaseTest } from '../shared/firebase/firebaseTest';
import ReceiveFuzziWorldJar from './ReceiveFuzziWorldJar';


export default function WorldJar(props) {
  const user = useUser() ?? { uid: 'null' };
  const { setIsFuzziAnimating } = props;

  const activeBreakpoint = useSelector(selectActiveBreakpoint);

  const flexDirection = activeBreakpoint >= 3 ? 'row' : 'column';

  const actionText = props.isHomePage ? 
    <Typography variant="h2" className="header" style={{color: props.textColor ? props.textColor : 'white'}}>Try it out! <br/> Make someone's day</Typography> :
    <Typography variant="h2" className="header" style={{color: props.textColor ? props.textColor : 'white'}}>Try it out! Have <br/> someone make <br/> your day.</Typography>

  return (
    <div className="world-jar" style={{flexDirection: flexDirection}}>
        <div className="info-div">
            <Typography variant="h2" className="header" style={{color: props.textColor ? props.textColor : 'white'}}>{actionText}</Typography>
            <div className="buttons">
              { props.isHomePage ? null :
                <ReceiveFuzziWorldJar id="world-jar" buttonText="GET A COMMUNITY FUZZI" confirmButtonText="CONFIRM"/>
              }
                <AddToJar user={user} jarId='WVky3XXvwskpshTv8crK' world={true} title='ADD A WARM FUZZI'/>
            </div>
            {/* <div>
              <FirebaseTest />
            </div> */}
        </div>
        <div className="image-div">
            <img src={jarHome} alt="" className="image"/>
        </div>
    </div>
  );
}
