import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, Link, Typography } from '@material-ui/core';
import { getUserByEmail } from '../../../shared/firebase/firebase';
import './JarDialogs.css';
// import '../FuzziJarManager/FuzziJarManager.css';

export default function CollaboratorDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  return (
    <React.Fragment className="collaborator-dialog">
      { props.isJarManager ?
          <div>
            <Link className="dialog-link-no-bold" color="" variant={props.variant} onClick={handleClickOpen}>
              {props.text}
            </Link>
            <div />
            <Typography variant="body2" align="left" className="edit-button-label">Contributors</Typography>
          </div>
        :
        <Link className="dialog-link" color="" variant={props.variant} onClick={handleClickOpen}>
          {props.text}
        </Link>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          { props.isJarManager ? 'Contributors' : 'Collaborators' }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { props.isJarManager ? 'Here are all the contributors who worked together to make this gift!' : 'Here are all the collaborators helped the author make this gift!'}
          </DialogContentText>
          <List>
            { props.collaborators.map((collaboratorEmail) => {
                console.log('collaborators: collaborators = ', props.collaborators);
                return (
                    <CollaboratorText collaboratorEmail={collaboratorEmail}/>
                );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function CollaboratorText(props) {

    const [collaboratorInfo, setCollaboratorInfo] = React.useState({});

    const getCollaboratorInfo = async() => {
        var collaboratorInfo = await getUserByEmail(props.collaboratorEmail);
        try {
            if (Object.keys(collaboratorInfo).length === 0) {
                const noAccountInfo = {
                    email: props.collaboratorEmail,
                    name: ''
                }
                setCollaboratorInfo(noAccountInfo);
            } else {
                setCollaboratorInfo(collaboratorInfo);
            }
        } catch (err) {
            const noAccountInfo = {
                email: props.collaboratorEmail,
                name: ''
            }
            setCollaboratorInfo(noAccountInfo);
        }
    };

    function returnUserString(userInfo, defaultEmail) {
        const userString = userInfo.name !== '' ? userInfo.name : userInfo.email !== '' ? userInfo.email : defaultEmail;
    
        return userString;
    }

    useEffect(() => {
        getCollaboratorInfo();
    }, [props])

    return (
        <ListItem>
            <ListItemText
                primary={returnUserString(collaboratorInfo, props.collaboratorEmail)}
            />
        </ListItem>
    );
}