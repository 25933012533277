import React from 'react';
import { Button } from '@material-ui/core';
import { selectIsFuzziAnimating, setIsFuzziAnimating } from '../DashboardSlice';
import { connect, useSelector } from 'react-redux';
import FuzziAnimation from './FuzziAnimation';

const mapDispatch = { setIsFuzziAnimating };

function TestFuzziAnimation(props) {
    
    const { setIsFuzziAnimating } = props;
    const isFuzziAnimating = useSelector(selectIsFuzziAnimating);

    const testFuzzi = {
        media: 'https://youtu.be/awopEDfXEpg',
        fuzziId: '1fWI8eleuSzNjA3wq7wL',
        author: 'cjcravens18@yahoo.com',
        isOpened: true,
        jarId: 'xHzbZ2gMyxAA70oLQ3i5',
        message: 'https://youtu.be/awopEDfXEpg'
    }

    const testFuzzi2 = {
        media: '',
        fuzziId: '1fWI8eleuSzNjA3wq7wX',
        author: 'cjcravens18@yahoo.com',
        isOpened: true,
        jarId: 'xHzbZ2gMyxAA70oLQ3i5',
        message: 'hello baby girl'
    }

    const handleOpenFuzzi = () => {
        setIsFuzziAnimating(true);
    }

    return (
        <div className="test-fuzzi-animation">
            <Button variant="contained" color="secondary" onClick={handleOpenFuzzi}>Open fuzzi</Button>
            { isFuzziAnimating ?
                <FuzziAnimation fuzzi={testFuzzi2}/> : null
            }
        </div>
    );
}

export default connect(null, mapDispatch)(TestFuzziAnimation);