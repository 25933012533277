import React, { useEffect } from 'react';
import './JarOption.css';
import { Button, Typography } from '@material-ui/core';
import StyleSheetColors from '../../../../../StyleSheet/StyleSheetColors';
import { createJarAsync, getJarsAsync, setIsViewingJar, setIsEditingJar } from '../../../DashboardSlice';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useUser } from 'reactfire';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapDispatch = { setIsViewingJar, setIsEditingJar };

function JarOption(props) {
    const dispatch = useDispatch();
    const user = useUser() ?? { uid: 'null' };

    const { setIsViewingJar, setIsEditingJar } = props;

    const details = props.details;

    let timer;

    const handleClick = () => {
        console.log('createJar: (1) handleClick');
        dispatch(createJarAsync({author: user.email}));
        dispatch(getJarsAsync(user.uid, "author"));

    }
    const buttonText = details.disabled ? 'Coming Soon' : 'CREATE';

    return (
        <div className="jar-option" style={{background: details.background ? StyleSheetColors.cardBackgroundPrimary : ''}}>
            <img src={details.src} alt="" className="jar"/>
            <Typography variant="h3">{details.title}</Typography>
            <div className="value-props">
                { details.valueProps.map((valueProp) => {
                    return (
                        <Typography variant="h5" align="left">{valueProp}</Typography>
                    );
                })}
            </div>
            
            <Button variant="contained" color="secondary" className="option-button" onClick={handleClick} disabled={details.disabled}>{buttonText}</Button>
        </div>
    );
}

export default connect(null, mapDispatch)(JarOption);