import React, { useEffect } from 'react';
import './Fuzzi.css';
import { Paper, Typography, IconButton, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import defaultProfilePic from '../../../assets/defaultProfilePic.png';
import ReactPlayer from 'react-player/lazy';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactTinyLink } from 'react-tiny-link';
import { getUserByEmail, deleteFuzzi } from '../../shared/firebase/firebase';
import { getFuzzisAsync } from '../DashboardSlice';
import { useDispatch } from 'react-redux';
import { useUser } from 'reactfire';
import Linkify from 'react-linkify';

export default function Fuzzi(props) {
    const dispatch = useDispatch();
    const fuzzi = props.fuzzi;
    const user = useUser() ?? { uid: 'null' };

    let media;

    let link = fuzzi.media ?? '';

    let url = link.split( '/' );

    console.log(`url::: ${url}`);

    let website = url[2] ?? '';

    let tweet = url[5] ?? '';

    let tweetArray = tweet.split('=');

    let tweetArray2 = tweetArray[0].split('?');

    let tweetId = tweetArray2[0];

    const giphyCheck = website.split('.');

    let title = fuzzi.title ? fuzzi.title : 'WARM FUZZI FOR YOU';
    let message = fuzzi.message ?? '';

    if (website === 'www.youtube.com' || website === 'youtube.com' || website === 'youtu.be') {
        const ampCheck = link.split('&');
        const youtube = ampCheck[0]
        media = <ReactPlayer url= {youtube} className="fuzzi-media-url" />

    } else if (website === 'twitter.com'){
        
        media = <div className="centerContent">
        <div className="selfCenter">
        <TwitterTweetEmbed tweetId={tweetId} className="fuzzi-media"/>
        </div>
        </div>
    } else if (website === 'firebasestorage.googleapis.com') {
        media = <div>
                    <LazyLoadImage
                        alt='fuzzi-media'
                        src={link}
                        className="fuzzi-media"
                    />
                </div>

    } else if (giphyCheck[1] === 'giphy') {
        media = <div>
                    <LazyLoadImage
                        alt='fuzzi-media'
                        src={link}
                        className="fuzzi-media"
                    />
                </div>
    } else if (website === '') {
        media = <div></div>;
    } else {
        media = <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={2}
            minLine={1}
            url={link}
            className="fuzzi-media"
        />
    }

    const [userInfo, setUserInfo] = React.useState({});

    useEffect(() => {
        getUserInfoAsync();
    }, [])

    const getUserInfoAsync = async() => {
        var userInfo = await getUserByEmail(fuzzi.author);
        try {
            setUserInfo(userInfo);
        } catch (err) {

        }
    };

    function returnUserString(userInfo, defaultEmail) {
        let userString = defaultEmail;
        if (Object.keys(userInfo).length > 0) {
            if (userInfo.name && userInfo.name !== '') {
                userString = userInfo.name;
            } else if (userInfo.email && userInfo.email !== '') {
                userString = userInfo.email;
            }
        }

        return userString;
    }

    const author = returnUserString(userInfo, fuzzi.author);

    const handleDelete = () => {
        deleteFuzzi(fuzzi.fuzziId);
        dispatch(getFuzzisAsync(fuzzi.jarId));
    }

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank">
          {fuzzi.message}
        </a>
      );

    return (
        <div className="fuzzi">
            { fuzzi.isOpened || props.isEditable || props.isAnimating ?
                <Paper elevation={3} className="fuzzi-container">
                    { props.isEditable && fuzzi.author === user.email ?
                        <IconButton onClick={handleDelete} className="delete">
                            <DeleteIcon />
                        </IconButton> : null
                    }
                    <div className="fuzzi-author">
                        <img src={userInfo.picture ?? defaultProfilePic} alt="" className="fuzzi-prof-pic"/>
                        <Typography variant="subtitle2" className="fuzzi-author-text">{author}</Typography>
                    </div>
                    { media ?
                        <div className="fuzzi-media-div">
                            {media}
                        </div> :
                        null
                    }
                    
                    <Box className="fuzzi-footer" component="div" whiteSpace="pre-line">
                        <Typography className="fuzzi-message" variant="body2" align="center">
                            <Linkify componentDecorator={componentDecorator}>{fuzzi.message}</Linkify>
                        </Typography>
                    </Box>
                </Paper> :
                <Paper className="unopened-fuzzi"/>
            }
            
        </div>
    );
}