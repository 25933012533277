import React from 'react';
import './App.css';
import Home from './components/Home/Home'
import Jars from './components/Jars/Jars';
import Discover from './components/Discover/Discover';
import AuthComponent from './components/Auth/Auth';
// import Login from './components/Auth/Login';
import Profile from './components/Profile/Profile';
// import Welcome from './components/Welcome';
// import Nav from './components/Nav/Nav';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MuiTheme from './MuiTheme';
import Dashboard from './components/Dashboard/Dashboard';
// import { config } from 'dotenv';

export default function App() {
  // if (process.env.NODE_ENV !== 'production') {
  //   config();
  // }
  
  const theme = MuiTheme();
  
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <div className="App">
          {/* <Nav /> */}
          <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/dashboard" exact component={Dashboard}/>
            <Route path="/jars" exact component={Jars}/>
            <Route path="/discover" exact component={Discover}/>
            <Route path="/login" component={AuthComponent}/>
            <Route path="/profile" exact component={Profile}/>
            {/* <Route path="/welcome" exact component={Welcome}/>
            <Route path="/editProfile" exact component={Welcome}/> */}
          </Switch>
        </div>
      </Router>
    </MuiThemeProvider>
  );
}
