import React from 'react';
import { selectActiveJar, selectIsViewingJar } from '../DashboardSlice';
import { useSelector } from 'react-redux';
import FuzziJarManager from './FuzziJarManager/FuzziJarManager';
import GiftFuzziJars from './GiftFuzziJars/GiftFuzziJars';
import MyFuzzis from '../MyFuzzis/MyFuzzis';
import './FuzziJarPage.css';

export default function FuzziJarPage(props) {


    const isViewingJar = useSelector(selectIsViewingJar);

    const Page = props.page === 'myFuzzis' ? <MyFuzzis /> : <GiftFuzziJars />
    const isEditable = props.page === 'myFuzzis' ? false : true

    console.log('fuzziPage: (3) isViewingJar = ', isViewingJar);

    return (
        <div className="fuzzi-jar-page">
            { isViewingJar ? 
                <FuzziJarManager isEditable={isEditable}/> :
                <div className="view-jar-page">
                    { Page }
                </div>
            }
        </div>
    );
}