import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../components/Auth/authSlice';
import homeReducer from '../components/Home/homeSlice';
import dashboardReducer from '../components/Dashboard/DashboardSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    home: homeReducer,
    dashboard: dashboardReducer
  },
});
