import React, { Suspense } from 'react';
import './Profile.css';
import { auth } from '../../index';
// import { Redirect } from 'react-router-dom';

// import { useSelector } from 'react-redux';
// import { selectNewUser } from '../Auth/authSlice';
import { useUser, AuthCheck } from 'reactfire';

import { Button } from '@material-ui/core';
import Auth from '../Auth/Auth';

 export default function AuthComponent(props) {

    return (
        <Suspense fallback={''}>
            <AuthProfile />
        </Suspense>
    );
  }
  
  
  function AuthProfile(props) {
    const user = useUser();
    // const newUser = useSelector(selectNewUser);

    return (
      <div className="authForm">
          <AuthCheck fallback={<Auth />}>
              <Profile user={user} />
          </AuthCheck>
      </div>
  
    );
  }


  export function Profile(props) {

    // const userProfile = useSelector(selectUserProfile);

    // console.log(`email: ${userProfile.email}`);


    return (
        <div className="profile">
            <Button variant="contained" color="primary" className="sign-out" href="/login" onClick={() => auth.signOut()}>Sign out</Button>
        </div>
        
      );
}