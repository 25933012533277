import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { FirebaseAppProvider } from 'reactfire';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';


export const firebaseConfig = {
  apiKey: "AIzaSyCcKlcXh0jBrP4jdfViddT3B46FB5MbVs8",
  authDomain: "warm-fuzzies-dev.firebaseapp.com",
  databaseURL: "https://warm-fuzzies-dev.firebaseio.com",
  projectId: "warm-fuzzies-dev",
  storageBucket: "warm-fuzzies-dev.appspot.com",
  messagingSenderId: "700650869033",
  appId: "1:700650869033:web:7c17389209398e99102738",
  measurementId: "G-Z4QRM5V0WP"
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

export { db, auth, functions, storage, firebase };