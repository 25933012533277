import React, { useEffect } from 'react';
import './MyFuzzis.css';
import { getJarsAsync, selectJarsGifted, selectJarsReceived, getJarsByCollaboratorAsync } from '../DashboardSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useUser } from 'reactfire';

import MyFuzziSection from '../FuzziJarPage/MyFuzziSection/MyFuzziSection';

export default function MyFuzzis() {
    const user = useUser() ?? { uid: 'null' };
    const dispatch = useDispatch();

    const jarsGifted = useSelector(selectJarsGifted);
    const jarsReceived = useSelector(selectJarsReceived);

    useEffect(() => {
        console.log('getJars: (1) MyFuzzis - useEffect');
        dispatch(getJarsAsync(user.email, "recipient"));
        dispatch(getJarsAsync(user.email, "author"));
        dispatch(getJarsByCollaboratorAsync(user.email));
    }, [])

    return (
        <div className="my-fuzzis">
            <MyFuzziSection title={"Jars I've Received"} jars={jarsReceived} role="recipient" emptyMessage="You haven't received any jars yet"/>
            <MyFuzziSection title={"Jars I've Gifted"} jars={jarsGifted} role="gifter" emptyMessage="Click 'Create a Jar' to starting make your first gift!"/>
        </div>
    );
}
