import React from 'react';
import './HomeSection3.css';
import { Typography } from '@material-ui/core';
import fuzziEx2 from '../../../assets/fuzziEx2.png';
import writing from '../../../assets/writing.png';
import { useSelector } from 'react-redux';
import { selectActiveBreakpoint } from '../homeSlice';

export default function HomeSection1(props) {

    const activeBreakpoint = useSelector(selectActiveBreakpoint);

    const flexDirection = activeBreakpoint >= 3 ? 'row' : 'column';

    return (
        <div className="home-section-3" style={{flexDirection: flexDirection}}>
            <div className="info-div">
                <img src={fuzziEx2} alt="" className="image"/>
                <Typography variant="h3" color="primary" className="text">Let your creativity run wild with <br/> embedded images and videos</Typography>
            </div>
            <div className="image-div">
                <img src={writing} alt="" className="image"/>
            </div>
        </div>
    );
}